import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import { useTranslation } from 'react-i18next';

//class SidebarContent extends Component {
const SidebarContent = ({onClick}) => {
  const { t, i18n } = useTranslation(['common']);
  const { language } = i18n;
  const destinationId = useSelector(state => state.gh.destinationId);
  const navigation = useSelector(state => state.gh.navigation);
  const basePath = useSelector(state => state.gh.basePath);
  const trip = useSelector(state => state.gh.trip);
  const user = useSelector(state => state.usr.user);
  const program = trip?.publicProgram;

  const hideSidebar = () => {
    onClick();
  };

  let fixLinks = null;
  if (destinationId) {
    fixLinks = (
      <ul className="sidebar__block">
        <SidebarLink title={t('navigation.welcome')} route={`${basePath}/welcome`} onClick={hideSidebar} icon="home" />
        <SidebarLink title={t('navigation.contact')} route={`${basePath}/contact`} onClick={hideSidebar} icon="users" />
      </ul>
    );
  }
  // ... Load GuestHouse navigation
  const cardLinks = navigation.map(nav => (
    <SidebarLink key={nav.Id} title={nav.getDescription(language)} route={`${basePath}/infocards/${nav.Id}`} onClick={hideSidebar} icon={nav.icon} />
  ));

  // ... In case a program is loaded, load program navigation and use dropdpwn menu's
//TODO - show badge for unpublished days
  let programNav = null;
  let programLinks = [];
  if (trip) {     
    // Program loaded for a specific trip. If logged in as tourguide : show all dates
    if (user && trip.tourguides.findIndex(elt => elt === user?.contactId) > -1 && user?.isTourGuideRole) {
      // ... user is logged in and is a tourguide for this trip.
      const end = trip.end;
      const start = trip.start;
      const nbrDays = (end - start) / (24*60*60*1000);
      for (let i = 0; i <= nbrDays; i++ ) {
        let pgDate = new Date(start);
        pgDate.setDate(start.getDate() + i);
        const key = pgDate.toISOString().substring(0,10);
        const unPubl = program.findIndex(elt => elt === key) === -1;
        programLinks.push(<SidebarLink key={key} title={pgDate.toLocaleDateString(language, {weekday: 'long', month: 'short', day: 'numeric'})} route={`${basePath}/program/${key}`} onClick={hideSidebar} icon='chevron-right' unPublishedLink={unPubl}/>);
      }
    } else {
      programLinks = program.map(key => {
        const pgDate = new Date(key);
        return <SidebarLink key={key} title={pgDate.toLocaleDateString(language, {weekday: 'long', month: 'short', day: 'numeric'})} route={`${basePath}/program/${key}`} onClick={hideSidebar} icon='chevron-right'/>
      });
    }
    if (programLinks?.length > 0) {
      programNav = (
      <ul className="sidebar__block">
          <SidebarCategory title={t('navigation.program')} icon="menu">
            {programLinks}
          </SidebarCategory>
        </ul>
      );
    }
  }
  const more = [
    <SidebarLink key="about" title={t('navigation.about')} route="/about" onClick={hideSidebar} />
  ];
  return (
    <div className="sidebar__content">
      {fixLinks}
      {programNav}
      {cardLinks ? (
        <ul className="sidebar__block">
          {cardLinks}
        </ul>
      )
        : null}
      <ul className="sidebar__block">
        <SidebarCategory title={t('navigation.more')} icon="cog">
          {more}
        </SidebarCategory>
      </ul>
    </div>
  );
}

export default SidebarContent;

SidebarContent.propTypes = {
  // changeToDark: PropTypes.func.isRequired,
  // changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

    /*
    const { changeToDark, changeToLight } = this.props;
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarLink title="Log In" icon="exit" route="/log_in" onClick={hideSidebar} />
          <SidebarCategory title="Layout" icon="layers">
            <button type="button" className="sidebar__link" onClick={changeToLight}>
              <p className="sidebar__link-title">Light Theme</p>
            </button>
            <button type="button" className="sidebar__link" onClick={changeToDark}>
              <p className="sidebar__link-title">Dark Theme</p>
            </button>
          </SidebarCategory>
        </ul>
        <ul className="sidebar__block">
          <SidebarCategory title="Example Pages" icon="diamond">
            <SidebarLink title="Page one" route="/pages/one" onClick={hideSidebar} />
            <SidebarLink title="Page two" route="/pages/two" onClick={hideSidebar} />
          </SidebarCategory>
        </ul>
      </div>
    );
    */
