/*
  programcontroller

  26/12   program controller to fetch/update program data
*/
import DirectusIO from './directusIO';
// import { getLangObject, getSizedImages } from './directusHelper';
import Log from '../../../helpers/Log/Log';
import ProgramDay from '../../models/programDay';
import ProgramDayLine from '../../models/programDayLine';
import Trip from '../../models/Trip';
const LOGMODULE = 'programcontroller';

export default class ProgramController {
  constructor(tenant) {
    this.dirIO = new DirectusIO(tenant);
  }

  /*
    Get programNavigation for a trip
    This function will check if a specific trip contains a program
    programs can be created by tourguides and can apply for more than one trip
    tripcode - unique trip code
    returns   array with dates where something is planned
              null is no program is found
  */
  async getProgramNavigation(tripcode) {
    Log.info(`getProgramNavigation for tripcode:${tripcode}`, LOGMODULE);

    if (!tripcode) {
      return null;
    }
    const param = {
      fields: 'date',
      'filter[program.trips.trip_period_code][eq]': tripcode,
      'filter[status][eq]': 'published',
      sort: 'date',
    };
    const json = await this.dirIO.getItems('trip_program_detail', param);
    const program = [];
    let prevDate = null;
    json.data.forEach((res) => {
      if (res.date !== prevDate) {
        program.push(res.date);
        prevDate = res.date;
      }
    });
    return program;
  }

  /*
    Get info for a trip
    This function will get basic info for the trip
    tripcode - unique trip code
    returns   Trip model
              null is no trip is found
  */
  async getTripInfo(tripcode) {
    Log.info(`getTripInfo for tripcode:${tripcode}`, LOGMODULE);

    if (!tripcode) {
      return null;
    }
    const param = {
      fields: 'start, end, programs.trip_program_id.tourguide',
      'filter[code][eq]': tripcode,
      'filter[status][eq]': 'published',
    };
    const json = await this.dirIO.getItems('trip_period', param);
    if (json.data.length === 0) {
      return null;
    }
    const trip = new Trip();
    trip.tripCode = tripcode;
    trip.start = new Date(json.data[0].start);
    trip.end = new Date(json.data[0].end);
    json.data[0].programs.forEach((pg) => {
      trip.tourguides.push(pg.trip_program_id.tourguide)
    })
    return trip;
  }
  /*
    Get programNavigation for a trip
    This function will check if a specific trip contains a program
    programs can be created by tourguides and can apply for more than one trip
    tripcode - unique trip code
    date      date to get the program for (maybe we should delete that field in the future)
    tourGuideId In case a tourguide is logged in - fetch all data for admin modus
    dayNbr    (only if tourguideId is passed) - Day number where template will be loaded for
    returns   array with programDay models for the give trip, date
              array with programDayLine models for the given trip, date
              null is no program is found

    Tour guide admin modus:
    - we will fetch all program data for this tourguide, but also non published info
    - we will fetch program data for other tourguides (also not published)
    - in case no data for this tourguide, we will fetch data from the template
  */
  async getProgramDetail(tripcode, date, tourGuideId = null, dayNbr = null, programTemplate = null) {
    Log.info(`getProgramDetail for tripcode:${tripcode}, date:${date}`, LOGMODULE);

    if (!tripcode || !date) {
      return null;
    };
    const prog = [];
    let jsonLines = [];
    let jsonPrograms = null;
    if (tourGuideId) {
          // editing - fetch all programs for this trip
      const param0 = {
        fields: 'id, tourguide, description, program_template.*.*',
        'filter[trips.trip_period_code][eq]': tripcode,
      };
      const query0 = this.dirIO.getProtectedItems('trip_program', param0);

      // editing - fetch program detail for given tourguide
      const param1 = {
        fields: '*, program.id, program.tourguide',
        'filter[program.trips.trip_period_code][eq]': tripcode,
        'filter[date][eq]': date,
        'filter[program.tourguide][eq]': tourGuideId,
      };
      const query1 = this.dirIO.getProtectedItems('trip_program_detail', param1);
      // editing - fetch program detail of other tourguides
      const param2 = {
        fields: '*, program.id, program.tourguide',
        'filter[program.trips.trip_period_code][eq]': tripcode,
        'filter[date][eq]': date,
        'filter[status][eq]': 'published',
        'filter[program.tourguide][neq]': tourGuideId,
      }
      const query2 = this.dirIO.getProtectedItems('trip_program_detail', param2);
      const result = await Promise.all([query0, query1, query2])
      jsonPrograms = result[0].data;
      let json1 = result[1];
      let json2 = result[2];
      jsonLines = json1.data.concat(json2.data);
      jsonPrograms.forEach(pg => {
        if (pg.tourguide.id === tourGuideId) {
          // if no data found for a program, loads its templates (for the current tourguide)
          if (json1.data.findIndex(elt => elt.program.id === pg.id) === -1 ) {
            if (pg.program_template) {
              const temp = pg.program_template?.program_template_detail;
              const iDayIndex = temp.findIndex(elt2 => elt2.day === dayNbr);
              if (iDayIndex > -1) {
                jsonLines.push({...temp[iDayIndex],
                  program: {
                    id: pg.id,
                    tourguide: tourGuideId,
                    status: 'draft',
                  }
                })
              }  
            }
          }
        }
      });
    } else {
      // no admin - fetch only published programs for given date
      const param0 = {
        fields: 'id, tourguide.*, description, program_template',
        'filter[trips.trip_period_code][eq]': tripcode,
      };
      const query0 = this.dirIO.getItems('trip_program', param0);
      const param = {
        fields: '*, program.id, program.tourguide',
        'filter[program.trips.trip_period_code][eq]': tripcode,
        'filter[status][eq]': 'published',
        'filter[date][eq]': date,
        sort: 'date',
      };
      const query1 = this.dirIO.getItems('trip_program_detail', param);
      const result = await Promise.all([query0, query1])
      jsonPrograms = result[0].data;
      jsonLines = result[1].data;
    }

    // fill programs array of programDay model
    jsonPrograms.forEach(pg => {
      const programDay = new ProgramDay(date, pg.id, pg.description, pg.tourguide.id);
      prog.push(programDay);  
    })

    let fieldtime = '';
    let fieldtitle = '';
    let fieldlocation = '';
    let fieldremark = '';
    let i = 0;
    let iPg = 0;
    const progLines = [];
    jsonLines.forEach((res) => {  
      for (i = 0; i < 10; i++) {
        fieldtime = `row${i+1}_time`;
        fieldtitle = `row${i+1}_title`;
        fieldlocation = `row${i+1}_location`;
        fieldremark = `row${i+1}_remark`;
        if (res[fieldtitle]) {
          progLines.push(new ProgramDayLine(res.program.id, i+1, (res.program?.tourguide == null ? true : res.program.tourguide === tourGuideId), res[fieldtime], res[fieldtitle], res[fieldlocation], res[fieldremark]));
        }
      };
      const published = res.status === 'published';
      iPg = prog.findIndex(elt => elt.programId === res.program.id)
      if (iPg > -1) {
        prog[iPg].published =published;
      }
    });
    return [prog, progLines];
  }

  /* save Program for a specific tourguide
    I tripcode    tripcode where the program is for
    I date        date
    I tourGuideId Tourguide Id
    I programLines  programLines !! Will also contain lines for (main) tourguide - see editable flag
        only lines with editable flag are for the tourguide
    I published   may this date be published for this tourguide

    Returns       true - if save was successfull
                  Throws error in case save fails
  */
  async saveProgram (tripcode, date, tourGuideId, programLines, published) {
    Log.info(`saveProgramDetail for tripcode:${tripcode}, date:${date}, tourguide:${tourGuideId}, published:${published}`, LOGMODULE);

    // get programId for this tourguide
    const param1 = {
        fields: 'id',
        'filter[trips.trip_period_code][eq]': tripcode,
        'filter[tourguide][eq]': tourGuideId,
      };
    const result1 = await this.dirIO.getProtectedItems('trip_program', param1);
    Log.trace(`Fetched all programs for this tourguide`)
    Log.trace(result1);
    // for each program for this tourguide ... save the lines
    for (const program of result1.data) {
      const programId = program.id
      Log.trace(`Update Program with Id ${programId}`)
      // get program detail for given date
      const dt = new Date(date);
      const param2 = {
        fields: '*',
        'filter[program][eq]': programId,
        'filter[date][eq]': dt.toISOString().substring(0,10),
      };
      Log.trace('Fetch detail lines')
      const result2 = await this.dirIO.getProtectedItems('trip_program_detail', param2);
      Log.trace(result2)
      let progDetId = null;
      if (result2.data.length > 0) {
        progDetId = result2.data[0].id;
      }

      // save program lines
      let i = 0;
      let body = {
        "date": dt.toISOString().substring(0,10),
        "program": programId,
        "status": published ? "published" : "draft",
      }
      programLines.forEach((line) => {
        if (line.editable && line.programId == programId) {
          // this is a line for the current tourguide
          body[`row${i+1}_time`] = line.hour.substring(0,5) + ":00";
          body[`row${i+1}_title`] = line.title;
          body[`row${i+1}_location`] = line.location;
          body[`row${i+1}_remark`] = line.detail;
          i++
        }
      });

      // set rest to blank
      let j = 0;
      for (j = i; j < 10; j++) {
        body[`row${j+1}_time`] = '';
        body[`row${j+1}_title`] = '';
        body[`row${j+1}_location`] = '';
        body[`row${j+1}_remark`] ='';
      };
      Log.trace('New Body for new program detail')
      Log.trace(body)
      let res3 = null;
      if (progDetId) {
        res3 = await this.dirIO.updateProtectedItem('trip_program_detail', progDetId, body);
      } else {
        res3 = await this.dirIO.createProtectedItem('trip_program_detail', body);
      }
      Log.trace('Result of saved line');
      Log.trace(res3);
    }
    return true;
  }
}
