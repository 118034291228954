import React from 'react';
import { Spinner } from 'reactstrap';

const loading = () => (
  <div className="load">
    <div className="load__icon-wrap">
      <Spinner type="grow" color="primary" />
    </div>
  </div>
);

export default loading;
