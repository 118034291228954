import React from 'react';
import { useCookies } from 'react-cookie';
import {
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const COOKIE_CONSENTDATE = 'mtb_consentDate';
export const COOKIE_NECESSARY = 'mtb_NecessaryCookies';
export const COOKIE_PREFERENCE = 'mtb_PreferenceCookies';
export const COOKIE_STATISTICAL = 'mtb_StatisticalCookies';
export const COOKIE_ADS = 'mtb_AdCookies';
// export const COOKIE_noeltest = 'noeltest';

const CookieConsent = () => {
  const [cookies, setCookie, removeCookie] = useCookies(
    [COOKIE_CONSENTDATE, COOKIE_PREFERENCE, COOKIE_STATISTICAL, COOKIE_ADS],
  );
  const { t, i18n } = useTranslation('common');
  const nextYear = new Date();
  nextYear.setFullYear(new Date().getFullYear() + 1);
  const consentOptions = { path: '/', expires: nextYear };
  const appPrefCookies = [];

  const setConsented = () => {
    setCookie(COOKIE_CONSENTDATE, new Date(), consentOptions);
    setCookie(COOKIE_NECESSARY, true, consentOptions);
    if (COOKIE_PREFERENCE !== 'true') {
      // in case preference cookies have been set to false, delette all saved cookies
      appPrefCookies.map((appCookie) => removeCookie(appCookie, { path: '/' }));
    }
  };

  const concentAll = () => {
    setCookie(COOKIE_CONSENTDATE, new Date(), consentOptions);
    setCookie(COOKIE_NECESSARY, true, consentOptions);
    setCookie(COOKIE_PREFERENCE, true, consentOptions);
    setCookie(COOKIE_STATISTICAL, true, consentOptions);
    setCookie(COOKIE_ADS, true, consentOptions);
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };
  return (
    <div>
      <Modal isOpen={!cookies[COOKIE_CONSENTDATE]} centered fade>
        <ModalBody>
          <div className="cookie-consent_navlang">
            {i18n.options.supportedLngs.map((lng) => {
              if (lng !== 'cimode') {
                return (
                  <button
                    key={lng}
                    className="cookie-consent_navlang_item"
                    type="button"
                    onClick={() => changeLanguage(lng)}
                  >
                    {lng}
                  </button>
                );
              }
              return null;
            })}
          </div>
          {t('cookie.cookietitle')}
          <Link to="/about"> {t('cookie.cookietitlelinktext')}</Link>
          <Form>
            <FormGroup check>
              <Label size="sm">
                <Input type="checkbox" checked disabled />
                <b> {t('cookie.necessary')}</b> {t('cookie.necessarydescr')}
              </Label>
              <Label size="sm">
                <Input
                  type="checkbox"
                  checked={cookies[COOKIE_PREFERENCE] === 'true'}
                  onChange={(e) => setCookie(COOKIE_PREFERENCE, e.target.checked, consentOptions)}
                />
                <b> {t('cookie.preference')}</b> {t('cookie.preferencedescr')}
              </Label>
              <Label size="sm">
                <Input
                  type="checkbox"
                  checked={cookies[COOKIE_STATISTICAL] === 'true'}
                  onChange={(e) => setCookie(COOKIE_STATISTICAL, e.target.checked, consentOptions)}
                />
                <b> {t('cookie.statistical')}</b> {t('cookie.statisticaldescr')}
              </Label>
              <Label size="sm">
                <Input
                  type="checkbox"
                  checked={cookies[COOKIE_ADS] === 'true'}
                  onChange={(e) => setCookie(COOKIE_ADS, e.target.checked, consentOptions)}
                />
                <b> {t('cookie.advertising')}</b> {t('cookie.advertisingdescr')}
              </Label>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setConsented()} color="link" size="sm">{t('cookie.savepref')}</Button>
          <Button onClick={() => concentAll()} color="primary">{t('cookie.saveall')}</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default CookieConsent;
