/* eslint-disable dot-notation */
/* eslint-disable no-else-return */
export default class Destination {
  constructor(id, name, alias) {
    this.destinationID = id;
    this.name = name;
    this.alias = alias;
    this.introText = [];
    this.destinationImage = '';
    this.destinationImageType = '';
    this.destinationImage_Thumbnails = []; // key=small/medium/large/xlarge url:the url width:width
    this.address = {
      street: '',
      zipcode: '',
      city: '',
      country: '',
      coordinates: null,
    };
    this.contact1 = null;
    this.contact1Type = 'OWN';
    this.contact2 = null;
    this.contact2Type = 'KEY';
  }

  getIntroText(lng, fallBackLng) {
    if (lng in this.introText) {
      return this.introText[lng];
    } else {
      return this.introText[fallBackLng];
    }
  }

  getImageSrcSet() {
    if (typeof this.destinationImage_Thumbnails !== 'undefined') {
      const srcSet = this.destinationImage_Thumbnails.map((element) => (`${element.url} ${element.width}`));
      return srcSet.toString();
    } else {
      return '';
    }
  }
}
