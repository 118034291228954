import {
  GUESTHOUSE_APP_STARTUPSTART,
  GUESTHOUSE_APP_STARTUPVALIDGH,
  GUESTHOUSE_APP_STARTUPSUCCESS,
  GUESTHOUSE_APP_STARTUPFAIL,
  GUESTHOUSE_APP_STARTUPINVALIDGH,
} from '../actions/guestHouseActions';

const initialState = {
  tenant: '',
  alias: null,
  destinationId: '',
  basePath: null,
  tripcode: null,
  trip: null,              // see trip model
  supportedLngs: null,
  fallBackLng: 'en',
  navigation: [],
  invalid: false,
  error: '',
};

export default function guestHouseReducer(state = initialState, action) {
  switch (action.type) {
    case GUESTHOUSE_APP_STARTUPSTART:
      return {
        ...state,
        basePath: null,
      };
    case GUESTHOUSE_APP_STARTUPVALIDGH:
      return {
        ...state,
        tenant: action.tenant,
        alias: action.alias,
        destinationId: action.destinationId,
        basePath: action.basePath,
        tripcode: action.tripcode,
      };
    case GUESTHOUSE_APP_STARTUPSUCCESS:
      return {
        ...state,
        supportedLngs: action.supportedLngs,
        fallBackLng: action.fallBackLng,
        navigation: action.navigation,
        trip: action.trip ? {...action.trip} : null,
        invalid: false,
        error: '',
      };
    case GUESTHOUSE_APP_STARTUPFAIL:
      return {
        ...state,
        error: action.error,
      };
    case GUESTHOUSE_APP_STARTUPINVALIDGH:
      return {
        ...state,
        invalid: true,
      };
    default:
      return state;
  }
}
