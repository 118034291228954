import {
  INFOCARDS_FETCH_START,
  INFOCARDS_FETCH_SUCCESS,
  INFOCARDS_FETCH_FAIL,
} from '../actions/infoCardActions';

const initialState = {
  infoCards: [],
  loading: false,
  loadingMedia: false,
  loadingMediaError: '',
  loadedCategory: null,
  loadedSearchFilter: null,
  loadedSpecificCardId: '',
  error: '',
};

export default function infoCardsReducer(state = initialState, action) {
  switch (action.type) {
    case INFOCARDS_FETCH_START:
      return {
        ...state,
        loading: true,
        infoCards: [],
        loadedCategory: action.category,
        loadedSearchFilter: action.searchFilter,
        loadedSpecificCardId: action.specificCardId,
        error: '',
      };
    case INFOCARDS_FETCH_SUCCESS:
      return {
        ...state,
        infoCards: [...action.infoCards],
        loading: false,
        error: '',
      };
    case INFOCARDS_FETCH_FAIL:
      return {
        ...state,
        loading: false,
        infoCards: [],
        error: action.error,
      };
    default:
      return state;
  }
}
