import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Collapse } from 'reactstrap';
import DownIcon from 'mdi-react/ChevronDownIcon';
import AccountIcon from '@material-ui/icons/AccountCircleOutlined';
import { useTranslation } from 'react-i18next';
import TopbarMenuLink from './TopbarMenuLink';
import { userLogout } from '../../../redux/actions/index';
import log from '../../../helpers/Log/Log';

const LOGMODULE = 'TopbarProfile';

const TopbarProfile = () => {
  const { t } = useTranslation('common');
  const history = useHistory();
  const loggedInUser = useSelector(state => state.usr.user);
  const basePath = useSelector(state => state.gh.basePath);
  const tenant = useSelector(state => state.gh.tenant);
  const dispatch = useDispatch();
  const [collapse, setCollapse] = useState(false);

  // ... Handler for logging in
  const onLoginClick = () => {
    log.trace('onLoginClick', LOGMODULE);
    history.push(basePath + '/login');
  }

  // ... Handler for logging out
  const onLogoutClick = () => {
    log.trace('onLogoutClick', LOGMODULE);
    setCollapse(false);
    dispatch(userLogout(tenant));
  }

  if (!loggedInUser) {
    log.trace('render - not loggedin', LOGMODULE);
    return (
      <div className="topbar__profile">
        <button onClick={onLoginClick} type="button" className="topbar__login" data-testid="login">
            <p>{t('topnav.login')}</p>
          </button>
      </div>
    );
  }

  log.trace('render - loggedin', LOGMODULE);
  let avatar = <AccountIcon className="topbar__avatar-img" alt="avatar" data-testid="accounticon"/>
  if (loggedInUser.photoUrl) {
    avatar = <img className="topbar__avatar-img" src={loggedInUser.photoUrl} alt="avatar" />
  }
  return (
    <div className="topbar__profile">
      <button type="button" className="topbar__avatar" onClick={() => setCollapse(!collapse)} data-testid="profile">
        {avatar}
        <p className="topbar__avatar-name">{loggedInUser.userName}</p>
        <DownIcon className="topbar__icon" />
      </button>
      {collapse && <button type="button" className="topbar__back" onClick={() => setCollapse(!collapse)} />}
      <Collapse isOpen={collapse} className="topbar__menu-wrap">
        <div className="topbar__menu">
          <TopbarMenuLink title={t('topnav.logout')} icon="exit" clicked={onLogoutClick} path="/" data-testid="logout" />
        </div>
      </Collapse>

    </div>
  );
}

export default TopbarProfile;