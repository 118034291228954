import React from 'react';
import { useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { COOKIE_STATISTICAL, COOKIE_ADS } from '../../shared/components/cookieConsent/cookieConsent';

import { init, sendPageview, setTrackStatistical, setTrackAds } from './analytics';

export default function useGoogleAnalytics() {
  const location = useLocation();
  const [cookies] = useCookies(
    [COOKIE_STATISTICAL, COOKIE_ADS],
  );


  React.useEffect(() => {
    init();
  }, []);

  React.useEffect(() => {
    const currentPath = location.pathname + location.search;
    sendPageview(currentPath);
  }, [location]);

  React.useEffect(() => {
    setTrackStatistical(cookies[COOKIE_STATISTICAL] === 'true');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies[COOKIE_STATISTICAL]]);

  React.useEffect(() => {
    setTrackAds(cookies[COOKIE_ADS] === 'true');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies[COOKIE_ADS]]);
}
