import React from 'react';
import ColorIcon from 'mdi-react/CircleMediumIcon';
import PropTypes from 'prop-types';

const ProgramLegend = ( {filtered}) => {
  if (!filtered) return null;
  if (filtered?.length < 2) {
    // ... only 1 program for this tourguide
    return null;
  }
  const colors = ['#E5E7EA', '#ff4861', '#11D3B7', '#c88ffa'];


  let i = 0;
  const legend = filtered.map(pg => {
    i++;
    return (
      <div className="programLegend__item" key={pg.programId}>
        <ColorIcon color={colors[i-1]} />
        <div><p className="typography-message">{pg.programDescription}</p></div>
      </div>
    );
  });
  return (
    <div className="programLegend__container" data-testid="legend">
      {legend}
    </div>
  );
}

ProgramLegend.propTypes = {
  filtered: PropTypes.array.isRequired,
}

export default ProgramLegend;
