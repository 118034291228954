/*
  Router      Thoelen Noël 22/10/2020
  Represents the main router of the App
  Refactored the previous router. We created a seperate component to render the guesthouse routes
*/
import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import CookieConsent from '../../shared/components/cookieConsent/cookieConsent';
import useGoogleAnalytics from '../../helpers/analytics/useGoogleAnalytics';

import RouterGuestHouse from './RouterGuestHouse';
import InitApp from '../Init/initApp';
import About from '../About/about';
import NotFound404 from '../Blank/404/index';
import PWAUpdateService from '../../shared/components/PWAUpdateService/PWAUpdateService';
// import * as actions from '../../redux/actions/index';
import Log from '../../helpers/Log/Log';

const LOGMODULE = 'router';

const WrappedRoutes = () => {
  Log.info('render', LOGMODULE);
  return (
    <div>
      <Layout />
      <div className="container__wrap">
        <CookieConsent />
        <Switch>
          <Route exact path="/init" component={InitApp} />
          <Route exact path="/about" component={About} />
          <Route path="/:tenant/_trip/:tripcode" component={RouterGuestHouse} />
          <Route path="/:tenant/:alias" component={RouterGuestHouse} />
          <Route component={NotFound404} />
        </Switch>
      </div>
    </div>
  );
};

const Router = () => {
  Log.info('Render', LOGMODULE);
  useGoogleAnalytics();

  // or not shown
  return (
    <MainWrapper>
      <main>
        <Switch>
          <Route path="/" component={WrappedRoutes} />
        </Switch>
      </main>
      <PWAUpdateService />
    </MainWrapper>
  );
};

export default withRouter(withTranslation(['common'])(Router));
