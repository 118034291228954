/*
  guestHouse-info state mamagement
  This is the basic state which are use by all other components and containers for the pure guesthouse app part
  This state is loaded during startup -> see router
*/
import { getDataController, getProgramController, getUserController, InvalidGuestHouseError, SessionExpiredError } from '../../redux/controller/dataController';
import Log from '../../helpers/Log/Log';
import { getLogin, resetLogin } from '../../helpers/setting/settings';
import { userLoginSuccess } from './index';
import { addTenantTracker } from '../../helpers/analytics/analytics';

export const GUESTHOUSE_APP_STARTUPSTART = 'GUESTHOUSE_APP_STARTUPSTART';
export const GUESTHOUSE_APP_STARTUPVALIDGH = 'GUESTHOUSE_APP_STARTUPVALIDGH';
export const GUESTHOUSE_APP_STARTUPSUCCESS = 'GUESTHOUSE_APP_STARTUPSUCCESS';
export const GUESTHOUSE_APP_STARTUPFAIL = 'GUESTHOUSE_APP_STARTUPFAIL';
export const GUESTHOUSE_APP_STARTUPINVALIDGH = 'GUESTHOUSE_APP_STARTUPINVALIDGH';

// App started ...
function guestHouseAppStartUpStart() {
  return {
    type: GUESTHOUSE_APP_STARTUPSTART,
  };
}

// App url contains valid GuestHouse
function guestHouseAppStartupValidGH(tenant, alias, id, basePath, tripcode) {
  return {
    type: GUESTHOUSE_APP_STARTUPVALIDGH,
    tenant,
    alias,
    destinationId: id,
    basePath,
    tripcode,
  };
}

// App started (see router.jsx)
function guestHouseAppStartupSuccess(supportedLngs, fallBackLng, navigation, trip) {
  return {
    type: GUESTHOUSE_APP_STARTUPSUCCESS,
    supportedLngs,
    fallBackLng,
    navigation,
    trip,
  };
}

// App start failed ...
function guestHouseAppStartupFail(error) {
  return {
    type: GUESTHOUSE_APP_STARTUPFAIL,
    error,
  };
}

// App start failed ...
function guestHouseAppStartupInvalidGH() {
  return {
    type: GUESTHOUSE_APP_STARTUPINVALIDGH,
  };
}


/*
  guesthouseAppStart
  An specific guestHouse or trip is passed through the app. We will load all 
  base data for the guestHouse so all components can be rendered
  - appSettings - languages
  - navigation 
  Parameters:
  I   urlHost Used Hostname (at this moment, this is not supported yet)
  I   urlTenant  Specific tenant (project) on the host
  I   urlDestAlias  Either an alias is passed either a tripcode is passed
  I   urlTripcode   A tripcode is passed - the program for the trip is also loaded for the navigation.
  
  26/12/2020 Add an extra url parameter tripcode
  11/02/2021 Check if user was logged in during last session and if login cookie is still valid
*/
export function guestHouseAppStart(urlHost, urlTenant, urlDestAlias, urlTripcode) {
  return (dispatch) => {
    Log.info('guesthouseAppStart', 'guestHouseActions.js');
    dispatch(guestHouseAppStartUpStart());
    const tenant = urlTenant;
    const alias = urlDestAlias;
  
    if (!tenant && (!alias || !urlTripcode)) {
      Log.trace('no guestHouseId specified, return without id');
      dispatch(guestHouseAppStartupSuccess('', '', null, 'en', []));
    } else {
      const datactlChk = getDataController(tenant);
      if (urlTripcode) {
        Log.trace(`Check if tripcode found for tenant:${tenant} and tripcoce:${urlTripcode}`, 'guestHouseAppStart');
        datactlChk.checkTripcode(urlTripcode)
          .then((dest) => checkSuccess(dispatch, tenant, dest.alias ,dest.destinationId, urlTripcode))
          .catch((error) => checkFail(dispatch, error));
      } else {
        Log.trace(`Check if destination is found for tenant:${tenant} and alias:${alias}`, 'guestHouseAppStart');
        datactlChk.checkDestination(alias)
          .then((dest) => checkSuccess(dispatch, tenant, alias, dest.destinationId))
          .catch((error) => checkFail(dispatch, error));
      }
    }
  };
}

function checkSuccess(dispatch, tenant, alias, id, tripcode) {
  Log.trace('Destination is valid', 'guestHouseAppStart');
  let basePath = '';
  if (tripcode) {
    basePath = `/${tenant}/_trip/${tripcode}`;
  } else {
    basePath = `/${tenant}/${alias}`;
  }
  localStorage.setItem('basePath', basePath);
  dispatch(guestHouseAppStartupValidGH(tenant, alias, id, basePath, tripcode));
  // get AppSettings and cardNavigation so other components can use this info

  let supportedLngs = null;
  let fallBackLng = 'en';
  let tenantTrackerId = null;
  const datactl = getDataController(tenant);
  const prgctl = getProgramController(tenant);
  Promise.all([
    datactl.getAppSettings(),
    datactl.getCardNavigation(id),
    prgctl.getProgramNavigation(tripcode),
    prgctl.getTripInfo(tripcode),
  ]).then((result) => {
    Log.trace('succesfully fetched app settings', 'guestHouseAppStart');
    const settings = result[0];
    if (settings !== null) {
      Log.info(settings, 'guestHouseAppStart');
      ({ supportedLngs, fallBackLng, tenantTrackerId } = settings);
    }
    Log.trace('successfully fetched card navigation');
    const cardNavigation = result[1];
    let trip = null;
    if (tripcode) {
      trip = result[3];
      trip.publicProgram = result[2];
    }

console.log(tenantTrackerId);
    if (tenantTrackerId) {
      addTenantTracker(tenantTrackerId);
    }

    dispatch(guestHouseAppStartupSuccess(supportedLngs, fallBackLng, cardNavigation, trip));
  }).catch((error) => {
    Log.error(error, 'guestHouseAppstart');
    dispatch(guestHouseAppStartupFail(error));
  });

  // check if user was logged in ... refresh the user
  const loginId = getLogin();
  if (loginId) {
    const usrctl = getUserController(tenant);
    usrctl.refreshUser(loginId)
    .then((user) => {
      Log.info(`successfully reathenticated user with if ${loginId}`, 'guestHouseAppStart');
      if (user) {
        dispatch(userLoginSuccess(user));
      }      
    })
    .catch((error) => {
      if (error instanceof SessionExpiredError) {
        // sessio expired, reset loginId coockie
        Log.info(`Unable to reathenticate user with ${loginId}; session expired`, 'guestHouseAppStart');
      } else {
        // or user info not valid anymore or Admin iser, continue unauthenticated
        Log.error(`error during refresh logged in user ${error.message}`, 'guestHouseAppStart');
      }
      resetLogin();
    });
  }

  
}

function checkFail(dispatch, error) {
  if (error instanceof InvalidGuestHouseError) {
    Log.error(error.message, 'guestHouseAppStart');
    dispatch(guestHouseAppStartupInvalidGH());
  } else {
    Log.error(error, 'guestHouseInit');
    dispatch(guestHouseAppStartupFail(error.message));
  } 
}
