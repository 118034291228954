/*
  29/12 - Program detail row
    based on model programday line model
*/
import React, { Suspense, useState } from 'react';
import {
  Container,
  Col,
  Row,
  CardText,
  UncontrolledPopover,
  PopoverBody,
} from 'reactstrap';
import PropTypes from 'prop-types';
import Loading from '../../../../shared/components/loading/loading';
import { useTranslation } from 'react-i18next';
import LocationIcon from 'mdi-react/MapMarkerIcon';

const LOGMODULE = 'ProgramDetailRowComponent';

const ProgramDetailRowComponent = ({lineNbr, editLineHandler, title, hour, location, detail, editable, editMode, editColorIndex}) => {
  const { t } = useTranslation(['guesthouse', 'common']);
  const editButtonHandler = (event) => {
    event.preventDefault();
    editLineHandler(lineNbr);
  }
  let colorIndex = editColorIndex;
  if (colorIndex < 1 || colorIndex > 4) {
    colorIndex = 1;
  }

  const editButton = (
    <div className="admin_editLine">
      <button className="admin_button_small" onClick={editButtonHandler} ><img src={`/img/mtb-Edit_${colorIndex}.svg`} alt={t('program.editalt')} /></button>
    </div>
  );
  const infoButton = (
    <div className="admin_editLine">
      <button id="infoButton" className="admin_button_small"><img src='/img/mtb-info.svg' alt={t('program.infoalt')} /></button>
      <UncontrolledPopover placement="left" target="infoButton">
        <PopoverBody>{t('program.infomsg')}</PopoverBody>
      </UncontrolledPopover>
    </div>
  );

  return (
    <React.Fragment>
      <Container>
        {editMode && !editable && infoButton}
        {editMode && editable && editButton}
        <Row className="programDetailRow__top">
          <Col xs='9' md='9'lg='9'>
            <CardText tag="h5" className="typography-message"><b>{title}</b></CardText>
          </Col>
          <Col xs='3' md='3'lg='3' className="programDetail__hour">
            <CardText tag="h5" className="typography-message"><b>{hour}</b></CardText>
          </Col>
        </Row>
        <Row className="programDetailRow__bottom">
          <Col xs={{ size: '10', offset: 1 }}>
            <CardText className="typography-message">
              {detail}
              {location ? <div className="program_detail_location"><LocationIcon /> {location}</div> : null}
            </CardText>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

ProgramDetailRowComponent.propTypes = {
  lineNbr: PropTypes.number.isRequired,
  editLineHandler: PropTypes.func.isRequired,
  title:  PropTypes.string,
  hour: PropTypes.string,
  location: PropTypes.string,
  detail: PropTypes.string,
  editable: PropTypes.any,
  editMode: PropTypes.bool,
  editColorIndex: PropTypes.number
}

export default ProgramDetailRowComponent;

/*
<CardText
            className="typography-message"
            dangerouslySetInnerHTML={{ __html: infoCard.getDetail(language, fallBackLng) ? infoCard.getDetail(language, fallBackLng) : infoCard.getIntroText(language, fallBackLng) }}
          />
*/