import React from 'react'
import PropTypes from 'prop-types';
import {MapContainer, Marker, Popup, TileLayer} from 'react-leaflet';
import Log from '../../../helpers/Log/Log';

const AddressWithMap = ({ name, street, zipcode, city, country, coordinates}) => {
  const LOGMODULE = 'AddressWithMap2'
  Log.trace('render AddressWithMap', LOGMODULE)

  let map = null;
  if (coordinates) {
    map = (
      <MapContainer 
        className="leaflet-container" 
        center={[coordinates.lat, coordinates.lng]} 
        zoom={15}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker
          position={[
            coordinates.lat,
            coordinates.lng
          ]} 
        >
          <Popup>
            {name}
          </Popup>
        </Marker>
      </MapContainer>
    )
  }
  return (
    <React.Fragment>
      <p>
        {street}<br />
        {zipcode} {city}<br />
        {country}
      </p>
      <div className="mapContainer">
        {map}
      </div>
    </React.Fragment>
  )
};

AddressWithMap.propTypes = {
  name: PropTypes.string,
  street: PropTypes.string,
  zipcode: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  coordinates: PropTypes.object,
}

export default AddressWithMap;