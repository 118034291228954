/*
  28/12 - Program detail
    via route /program/yyyy-mm-dd (iso date format)
  15/02 - Add toolbar in case tourguide
*/
import React, { useEffect, useState, Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Log from '../../../helpers/Log/Log';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
} from 'reactstrap';
import Alert from '../../../shared/components/Alert';
import CardDetailTopNav from '../../../shared/components/cardDetailTopNav/cardDetailTopNav';
import ProgramDetailRowComponent from './Components/ProgramDetailRowComponent';
import ProgramLegend from './Components/ProgramLegend';
import Loading from '../../../shared/components/loading/loading';
import { programDetailFetch, programDeleteThunk, programSetPublishedState } from '../../../redux/actions/index';
import AdminButton from '../../Admin/Components/AdminButton';
import AdminToolBar from '../../Admin/Components/AdminToolbar/AdminToolbar';
import AddLineButton from '../../Admin/Components/AddLineButton';
import programDayLine from '../../../redux/models/programDayLine';
import Trip from '../../../redux/models/Trip';
const EditProgramLine = React.lazy(() => import('./Admin/ModalComponents/EditProgramLine'));

const LOGMODULE = 'programdetail';

const ProgramDetail = (props) => {
  Log.info('render', LOGMODULE);
  const { t, i18n } = useTranslation(['guesthouse', 'common']);
  const { language } = i18n;
  const { programDate = null } = props.match.params;        // fetch date from url (is in ISO date format)
  const dispatch = useDispatch();
  const [adminMode, setAdminMode] = useState(false);
  const basePath = useSelector(state => state.gh.basePath);
  const tripcode = useSelector(state => state.gh.tripcode);
  const tenant = useSelector(state => state.gh.tenant);
  const user = useSelector(state => state.usr.user);
  const programs = useSelector(state => state.pg.programs);
  const programLines = useSelector(state => state.pg.programLines);
  const loading = useSelector(state => state.pg.loading);
  const error = useSelector(state => state.pg.error);
  const saveError = useSelector(state => state.pg.saveError);
  const tripObject = useSelector(state => state.gh.trip);
  const [editLine, setEditLine] = useState(null);       // line to edit -1 is new
  const trip = new Trip(tripObject);
  let programNav = trip?.publicProgram;
  let programsForThisTourGuide = null;

  // ... check if tourguide for this trip
  let tourGuideForThisProgram = false;
  if (user && user?.isTourGuideRole && trip?.tourguides?.findIndex(elt => elt === user?.contactId) > -1) {
    tourGuideForThisProgram = true;
    programsForThisTourGuide = programs?.filter(elt => elt.tourguideId == user.contactId);
    programNav = trip.getProgramNav();
  }

  // ============== Hooks ========================
  // Effect when instructions are give to load another program date
  useEffect(() => {
    // another date, trip loaded - reset admin mode
    setAdminMode(false);
    Log.trace(`useEffect: programDate changed - ${programDate}`, LOGMODULE)
    let loadedProgramDate = null
    if (programs) {
      loadedProgramDate = programs[0].date;
    }
    if (loadedProgramDate !== programDate) {
      Log.trace(`load programdetail for tripcode ${tripcode} and date:${programDate}`, LOGMODULE);
      dispatch(programDetailFetch(tenant, tripcode, programDate));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant, tripcode, programDate]);

  useEffect(() => {
    // only act if admin mode is true. 
    if (adminMode) {
        const dayNbr = trip.getDayNbr(programDate)
        Log.trace(`load editable programdetail for tripcode ${tripcode} and date:${programDate} for tourguide ${user.contactId}`, LOGMODULE);
        dispatch(programDetailFetch(tenant, tripcode, programDate, user.contactId, dayNbr, trip.programTemplate));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminMode]);

  // =============== Handlers ========================
  const adminClickedHandler = () => {
    setAdminMode(true);
  }
  const editLineHandler = (lineNbr) => {
    setEditLine(lineNbr);
  }
  const EditCloseHandler = () => {
    setEditLine(null);
  }
  const confirmHandler = () => {
    setAdminMode(false);
  }
  const deleteHandler = () => {
    const r = window.confirm(t('programadmin.deleteall'));
    if (!r) {
      return
    }
    dispatch(programDeleteThunk());
  }
  const publisHandler = () => {
    dispatch(programSetPublishedState(true));
  }
  const unPublishHandler = () => {
    dispatch(programSetPublishedState(false));
  }

  // ===== prepare render ============================
  /*
  if (loading) {
    Log.trace('render loading', LOGMODULE);
    return <Loading />;
  }
  */
  if (error !== '') {
    Log.error(`error occurred in loading program: ${error}`, LOGMODULE);
    return (
      <Alert color="danger" icon>
        <p>{t('program.loaderror')}</p>
      </Alert>
    );
  }
  if (saveError !== '') {
    Log.error(`error occurred in saving program: ${saveError}`, LOGMODULE);
    return (
      <Alert color="danger" icon>
        <p>{t('programadmin.errorsave')}</p>
      </Alert>
    );
  }

  // determine index in array of date navigation
  if (!programNav) {
    Log.trace('no program navigation', LOGMODULE);
    return null /*(
      <Alert color="info" icon>
        <p>{t('program.noprogram')}</p>
      </Alert>
    );*/
  }
  let previousDay = null;
  let nextDay = null;
  const i = programNav.findIndex(x => x === programDate);
  if (i === -1 && !loading) {
    Log.trace(`programDetail  ${programDate}`, LOGMODULE);
    return (
      <Alert color="info" icon>
        <p>{t('program.noprogram')}</p>
      </Alert>
    );
  }
  if (i > 0) {
    previousDay = `${basePath}/program/${programNav[i - 1]}`;
  }
  if (i < programNav.length - 1) {
    nextDay = `${basePath}/program/${programNav[i + 1]}`;
  }
  const displayDate = new Date(programDate).toLocaleDateString(language, {weekday: 'long', month: 'short', day: 'numeric'});
  // ... generate program lines
  let programDetail = null;
  if (programLines) {
    let i=0;
    programDetail = programLines.map((elt) => {
      i++;
      return (
        <ProgramDetailRowComponent 
          key={i}
          lineNbr={i}
          editLineHandler={editLineHandler}
          hour={elt.hour} 
          title={elt.title} 
          location={elt.location}
          detail={elt.detail} 
          editable={elt.editable}
          editColorIndex={programsForThisTourGuide?.findIndex(pg => pg.programId === elt.programId) + 1}
          editMode={adminMode} 
        />
      )});
  }

  // Admin button/toolbar
  let adminButtonToolbar = null
  if (!adminMode) {
    if (tourGuideForThisProgram) {
      adminButtonToolbar = (
        <AdminButton
          tourGuideRole={true} 
          clickHandler={adminClickedHandler}
        />
      );
    }
  } else {
    // ... CHeck published state of the program of this tourguide
    if (programsForThisTourGuide && programsForThisTourGuide[0].published) {
      adminButtonToolbar = (
        <AdminToolBar
          alertMessage={t('program.published')}
          confirmButton
          confirmHandler={confirmHandler}
          deleteButton
          deleteHandler={deleteHandler}
          unpublishButton
          unpublishHandler={unPublishHandler}
        />
      );
    } else {
      adminButtonToolbar = (
        <AdminToolBar
          alertMessage={t('program.unpublished')}
          confirmButton
          confirmHandler={confirmHandler}
          deleteButton
          deleteHandler={deleteHandler}
          publishButton 
          publishHandler={publisHandler}
        />
      );
    }
  }

  // ... in case editLine -> fetch the object
  let editPopup = null;
  if (editLine) {
    let editLineObject = new programDayLine();
    if (editLine > -1) {
      editLineObject = programLines[editLine - 1];
    }
    editPopup = (
      <Suspense fallback={<div><Loading /></div>}>
        <EditProgramLine
          programLine={editLine} 
          lineObject={editLineObject} 
          closeHandler={EditCloseHandler}
          programs={programsForThisTourGuide}
        />
      </Suspense>
    );
  }

  // ... in case this tourguides manages more than 1 program ?
  //     show program legend at the bottom

  // ... render
  return (
    <React.Fragment>
      {adminButtonToolbar}
      <Container className="dashboard">
        <Row>
          <Col xs={12} md={12} lg={8}>
            <Card>
              <CardBody>
                <CardDetailTopNav
                  icon='menu'
                  title={displayDate}
                  navPrevious={!adminMode ? previousDay : null}
                  navNext={!adminMode ? nextDay : null}
                />
                {programDetail}
                {adminMode ?
                  <React.Fragment>
                    <Row>
                      <Col>
                        <AddLineButton clickHandler={() => editLineHandler(-1)} />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ProgramLegend filtered={programsForThisTourGuide} />
                      </Col>
                    </Row>
                  </React.Fragment>
                : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {editPopup}
      {loading && <Loading />}
    </React.Fragment>
  )
};

export default ProgramDetail;
