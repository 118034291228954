import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Loading from '../../../shared/components/loading/loading';
import Alert from '../../../shared/components/Alert';
import * as actions from '../../../redux/actions/index';
import ContactCard from './Components/ContactCard';
import Log from '../../../helpers/Log/Log';

const LOGMODULE = 'contact';
class ContactPage extends Component {
  componentDidMount() {
    Log.info('componentDidMount', LOGMODULE);
    if (this.props.destinationId !== '' || this.props.tripcode !== '') {
      Log.trace(`state has guestHouseId - fetchdata ${this.props.destinationId}`, LOGMODULE);
      this.props.onContactsFetch(this.props.tenant, this.props.destinationId, this.props.tripcode);
    }
  }

  componentDidUpdate(prevProps) {
    Log.info('componentDidUpdate', LOGMODULE);
    // loading was deferred in componentdidmount, so we do it now
    if (this.props.destinationId !== prevProps.destinationId || this.props.tripcode !== prevProps.tripcode) {
      Log.trace(`other guestHouseId - fetchdata ${this.props.destinationId}`, LOGMODULE);
      this.props.onContactsFetch(this.props.tenant, this.props.destinationId, this.props.tripcode);
    }
  }

  render() {
    Log.info('render', LOGMODULE);
    const { t } = this.props;
    if (this.props.loading) {
      Log.trace('render loading', LOGMODULE);
      return <Loading />;
    }
    if (this.props.error !== '') {
      Log.error(`error occurred in loading contacts: ${this.props.error}`, LOGMODULE);
      return (
        <Alert color="danger" icon>
          <p>{t('contact.loaderror')}</p>
        </Alert>
      );
    }
    if (this.props.contacts.length === 0) {
      Log.trace('render no contacts - array is empty', LOGMODULE);
      return (<p>{t('contact.nocontacts')}</p>);
    }
    let jsx = null;
    Log.trace('render contacts', LOGMODULE);
    jsx = (
      this.props.contacts.map(ct => (
        <Row key={ct.contactID}>
          <Col xs={12} md={12} lg={8}>
            <ContactCard contact={ct} fallBackLng={this.props.fallBackLng} />
          </Col>
        </Row>
      ))
    );
    return (
      <Container className="dashboard">
        <Row>
          <Col xs={12} md={12} lg={8}>
            <h3 className="page-title">{t('contact.title')}</h3>
          </Col>
        </Row>
        {jsx}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tenant: state.gh.tenant,
    fallBackLng: state.gh.fallBackLng,
    destinationId: state.gh.destinationId,
    tripcode: state.gh.tripcode,
    contacts: state.ctct.contacts,
    loading: state.ctct.loading,
    error: state.ctct.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onContactsFetch: (tenant, destinationId, tripcode) => dispatch(actions.contactsFetch(tenant, destinationId, tripcode)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['guesthouse', 'common'])(ContactPage));
