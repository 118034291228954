/*
  contactCard component - show a contact
  02/01/21 Added tourguide and main tour guide
*/

import React, { Fragment } from 'react';
import { Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import AddressWithMap from '../../../../shared/components/Address/AddressWithMap';
import PhoneLink from '../../../../shared/components/PhoneLink/PhoneLink';
import CardMedia from '../../../../shared/components/cardMedia/CardMedia';
import Log from '../../../../helpers/Log/Log';

const LOGMODULE = 'contactcard';

const ContactCard = (props) => {
  Log.trace('render', LOGMODULE);
  const { t, i18n } = useTranslation(['guesthouse', 'common']);
  const { language } = i18n;
  const { fallBackLng } = props;
  let weSpeak = null;
  if (props.contact.contactLanguages.length > 0) {
    weSpeak = <p>{t('contact.wespeak')} {props.contact.contactLanguages.map((lng) => (<Fragment key={lng}>{t(`common:languages.${lng}`)} </Fragment>))}</p>
  }
  return (
    <Card>
      <CardBody>
        <h3 className="page-title">{props.contact.name}</h3>
        <h3 className="subhead">{t(`contact.${props.contact.role}`)}</h3>
        <CardMedia
          mediaType={props.contact.photoType}
          imageSrcSet={props.contact.getImageSrcSet()}
          mediaUrl={props.contact.photo}
          mediaAltText=""
        />
        <p
          className="typography-message"
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{ __html: props.contact.getIntroText(language, fallBackLng) }}
        />
        <p><PhoneLink type="EMAIL" linkAddress={props.contact.email} linkData={props.contact.email} /></p>
        <p><PhoneLink type="PHONE" linkAddress={props.contact.phone} linkData={props.contact.phone} /></p>
        <p><PhoneLink type="WHATSAPP" linkAddress={props.contact.whatsapp} linkData={props.contact.whatsapp} /></p>
        {weSpeak}
        {props.contact.address.street !== '' ? (
          <>
            <br />
            <h5 className="bold-text">{t('contact.location')}</h5>
            <AddressWithMap
              coordinates={props.contact.address.coordinates}
              name={props.contact.name}
              street={props.contact.address.street}
              zipcode={props.contact.address.zipcode}
              city={props.contact.address.city}
              country={props.contact.address.country}
            />
          </>
        ) : null}
      </CardBody>
    </Card>
  );
};

export default ContactCard;
