import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import CancelIcon from '@material-ui/icons/Cancel';
import PhoneLink from '../PhoneLink/PhoneLink';
import Log from '../../../helpers/Log/Log';

const LOGMODULE = 'sharePage';

const SharePage = ({ t }) => {
  const [sharePageIsOpen, setSharePageIsOpen] = useState(false);
  const body = `${t('sharepage.bodytext')} ${window.location.href}`;
  const toggleOpen = () => {
    Log.info('toggle share page options open', LOGMODULE);
    setSharePageIsOpen(!sharePageIsOpen);
  };

  Log.info('render', LOGMODULE);

  let jsx = (
    <div className="sharePage">
      <button type="button" onClick={toggleOpen}>{t('sharepage.sharebutton')}</button>
    </div>
  );
  if (sharePageIsOpen) {
    jsx = (
      <div className="sharePage">
        <span className="sharePageOptions">
          {t('sharepage.sharetext')}&nbsp;
          <PhoneLink type="WHATSAPP" linkBody={body} />
          <PhoneLink type="EMAIL" linkBody={body} />
          <PhoneLink type="SMS" linkBody={body} />
          &nbsp;&nbsp;&nbsp;
          <CancelIcon fontSize="inherit" onClick={toggleOpen} />
        </span>
      </div>
    );
  }
  return (
    <div>
      {jsx}
    </div>
  );
};

export default withTranslation(['common'])(SharePage);
