/*
  directuscontroller

  31/08   added all_props to infocard -> show the infocard for all properties
  09/12   bug - getImageThumbnails was not working properly anymore. We alsways use GetSizedImages now
*/
/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable prefer-destructuring */
/* eslint-disable dot-notation */
/* eslint-disable no-else-return */
/* eslint-disable max-len */
/* eslint-disable arrow-parens */
/* eslint-disable class-methods-use-this */
import DirectusIO from './directusIO';
import { getLangObject, getSizedImages } from './directusHelper';
import Destination from '../../models/Destination';
import Contact from '../../models/Contact';
import Log from '../../../helpers/Log/Log';
import InfoCard from '../../models/InfoCard';
import Navigation from '../../models/Navigation';
import { InvalidGuestHouseError } from '../dataController';

const LOGMODULE = 'directuscontroller';

export default class DirectusController {
  constructor(tenant) {
    this.dirIO = new DirectusIO(tenant);
  }

  /*
    CHeck if a property exists based alias
      alias     currently used url

      Returns:  object with unique destinationID, alias
                null is case nothing is found
  */
  async checkDestination(alias) {
    Log.info(`checkDestination alias:${alias}`, LOGMODULE);
    let param = '';

    // ... no alias passed
    if (alias !== null && alias !== '') {
      param = {
        fields: 'id, alias',
        'filter[alias][eq]': alias,
      };
    } else {
      Log.warn('checkdestination: no alias specified', LOGMODULE);
      throw new InvalidGuestHouseError('Directus:checkDestination: no alias specified');
    }
    Log.trace('checkdestination: Fetch destination from directus ...', LOGMODULE);
    let json = null;
    try {
      json = await this.dirIO.getItems('destination', param);
    } catch (e) {
      if (e.message === 'Unauthorized request') {
        Log.error(`Directus rejected specified tenant: ${e.message}`, LOGMODULE);
        throw new InvalidGuestHouseError(e.message);
      } else {
        Log.error(`Error checking guesthouse: ${e.message}`, LOGMODULE);
        throw Error(e);
      }
    }
    if (json.data.length === 0) {
      Log.error(`Invalid alias:${alias}`, LOGMODULE);
      throw new InvalidGuestHouseError(`Invalid alias:${alias}`);
    }
    return { destinationId: json.data[0].id, alias: json.data[0].alias };
  }

  /*
    CHeck if a property exists based on trip-code
      tripcode     given tripcode

      Returns:  object with unique destinationID, alias
                null is case nothing is found
  */
 async checkTripcode(tripcode) {
  Log.info(`checkTripcode tripcode:${tripcode}`, LOGMODULE);
  let param = '';

  // ... no alias passed
  if (tripcode !== null && tripcode !== '') {
    param = {
      fields: 'destination_id.id, destination_id.alias',
      'filter[code][eq]': tripcode,
    };
  } else {
    Log.warn('checkTripcode: no tripcode specified', LOGMODULE);
    throw new InvalidGuestHouseError('Directus:checkTripcode: no tripcode specified');
  }
  Log.trace('checkTripcode: Fetch trip_period from directus ...', LOGMODULE);
  let json = null;
  try {
    json = await this.dirIO.getItems('trip_period', param);
  } catch (e) {
    if (e.message === 'Unauthorized request') {
      Log.error(`Directus rejected specified tenant: ${e.message}`, LOGMODULE);
      throw new InvalidGuestHouseError(e.message);
    } else {
      Log.error(`Error checking tripcode: ${e.message}`, LOGMODULE);
      throw Error(e);
    }
  }
  if (json.data.length === 0) {
    Log.error(`Invalid tripcode:${tripcode}`, LOGMODULE);
    throw new InvalidGuestHouseError(`Invalid tripcode:${tripcode}`);
  }
  return { destinationId: json.data[0].destination_id.id, alias: json.data[0].destination_id.alias };
}


  /*
    GetAppSettings for the current app (project in directus)
    Returns:  object with settings
      supportedLngs
      fallbackLngs
  */
  async getAppSettings() {
    Log.info(`getAppSettings:${this.dirIO.config.project}`, LOGMODULE);
    const json = await this.dirIO.getItems('appsettings');
    if (json.data.length === 0) {
      Log.warn('getappSettings: Warning - no settings found', LOGMODULE);
      return null;
    }
      return { supportedLngs: json.data[0].supported_lngs, fallBackLng: json.data[0].fallback_lng, tenantTrackerId: json.data[0].ga_trackerid };
  }

  /*
    Get a property by ID
    id - unique ID for the property
    lng - current app language
  */
  async getDestination(id) {
    Log.info(`getDestination by id: ${id}`, LOGMODULE);
    const param = {
      fields: '*,destinationimage.*,translation.*',
    };
    const json = await this.dirIO.getItem('destination', id, param);
    Log.trace('getDestination: Transform json to destination model', LOGMODULE);
    const dest = new Destination(id);
    dest.name = json.data.name;
    dest.alias = json.data.alias;
    // dest.introText = json.data.translation[0].introtext;
    dest.introText = getLangObject(json.data.translation, 'introtext');
    if (json.data.destinationimage) {
      // get sized images so alle image have the same size
      const arrSizes = [
        { key: '1x', sizeParam: 'w=350&h=350&f=contain&q=100', width: '' },
        { key: '2x', sizeParam: 'w=800&h=600&f=contain&q=100', width: '2x' },
        { key: '3x', sizeParam: 'w=1280&h=950&f=contain&q=100', width: '3x' },
      ];
      const url = `${this.dirIO.config.url}/${this.dirIO.config.project}/assets/`;
      [dest.destinationImage_Thumbnails, dest.destinationImage, dest.destinationImageType] = getSizedImages(url, arrSizes, json.data.destinationimage, false);
      
      
      // [dest.destinationImage_Thumbnails, dest.destinationImage, dest.destinationImageType] = getImageThumbnails(json.data.destinationimage.data.thumbnails, json.data.destinationimage);
    }
    // Set the image url to the smallest!
    // dest.destinationImage = dest.destinationImage_Thumbnails[0].url;
    dest.address.street = json.data.street;
    dest.address.zipcode = json.data.zipcode;
    dest.address.city = json.data.city;
    dest.address.country = json.data.country;
    if (json.data.coordinates !== null) {
      dest.address.coordinates = {
        lat: json.data.coordinates.lat,
        lng: json.data.coordinates.lng,
      };
    }
    dest.contact1 = json.data.contact1;
    dest.contact1Type = json.data.contact1_type;
    dest.contact2 = json.data.contact2;
    dest.contact2Type = json.data.contact2Type;
    Log.trace('getDestination: Transform json to destination model - done', LOGMODULE);
    return dest;
  }

  /*
    Get contact infor for a property
    destinationId - unique ID for the property
    returns   array with contacts (see models/contact)
    02/01 Added new parameter ... tripcode
  */
  async getContacts(destinationId, tripCode) {
    let contact1 = null, contact1Role = null, contact2 = null, contact2Role = null, tourguide = null, mainTourguide = null;
    console.log('trip', tripCode)
    if (tripCode) {
      // Tripcode : fetch destination contacts as also the tourguides
      Log.info(`getContacts for trip with code:${tripCode}`, LOGMODULE);
      const param = {
        fields: 'destination_id.contact1_type, destination_id.contact1.*, destination_id.contact1.photo.*, destination_id.contact2_type, destination_id.contact2.*, destination_id.contact2.photo.*,programs.trip_program_id.tourguide.*, programs.trip_program_id.tourguide.photo.*',
      };
      const json = await this.dirIO.getItem('trip_period', tripCode, param);
      Log.trace('getContacts: transform json to array of contacts', LOGMODULE);
      contact1 = json.data.destination_id.contact1;
      contact1Role = json.data.destination_id.contact1_type;
      contact2 = json.data.destination_id.contact2;
      contact2Role = json.data.destination_id.contact2_type;
      if (json.data.programs) {
        if (json.data.programs.length > 0 ) {
          tourguide = json.data.programs[0].trip_program_id.tourguide;    
        }
        if (json.data.programs.length > 1 ) {
          mainTourguide = json.data.programs[1].trip_program_id.tourguide;    
        }
      }
    } else {
      // no tripCode, fetch destination and his contacts
      Log.info(`getContacts for destination with id:${destinationId}`, LOGMODULE);
      const param = {
        fields: 'contact1_type, contact1.*, contact1.photo.*, contact2_type, contact2.*, contact2.photo.*',
      };
      const json = await this.dirIO.getItem('destination', destinationId, param);
      Log.trace('getContacts: transform json to array of contacts', LOGMODULE);
      contact1 = json.data.contact1;
      contact1Role = json.data.contact1_type;
      contact2 = json.data.contact2;
      contact2Role = json.data.contact2_type;
    }
    const contacts = [];
    if (tourguide) {
      const ct = new Contact(tourguide.id, tourguide.name);
      ct.role = 'TG';
      if (tourguide.photo) {
        // get sized images so alle image have the same size
        const arrSizes = [
          { key: '1x', sizeParam: 'w=350&h=350&f=contain&q=100', width: '' },
          { key: '2x', sizeParam: 'w=800&h=600&f=contain&q=100', width: '2x' },
          { key: '3x', sizeParam: 'w=1280&h=950&f=contain&q=100', width: '3x' },
        ];
        const url = `${this.dirIO.config.url}/${this.dirIO.config.project}/assets/`;
        [ct.photo_thumbnails, ct.photo, ct.photoType] = getSizedImages(url, arrSizes, tourguide.photo, false);

        //[ct.photo_thumbnails, ct.photo, ct.photoType] = getImageThumbnails(json.data.destination_owner.photo.data.thumbnails, json.data.destination_owner.photo);
      }
      ct.introText_ml = getLangObject(tourguide.contact_translation, 'welcome_text');
      ct.email = tourguide.email;
      ct.phone = tourguide.phone;
      ct.whatsapp = tourguide.whatsapp;
      ct.contactLanguages = [...tourguide.contact_languages];
      if (tourguide.street) {
        ct.address.street = tourguide.street;
        ct.address.zipcode = tourguide.zipcode;
        ct.address.city = tourguide.city;
        ct.address.country = tourguide.country;
        ct.address.coordinates = tourguide.address_coordinates;
      }
      contacts.push(ct);
    }
    if (mainTourguide) {
      const ct = new Contact(mainTourguide.id, mainTourguide.name);
      ct.role = 'TG';
      if (mainTourguide.photo) {
        // get sized images so alle image have the same size
        const arrSizes = [
          { key: '1x', sizeParam: 'w=350&h=350&f=contain&q=100', width: '' },
          { key: '2x', sizeParam: 'w=800&h=600&f=contain&q=100', width: '2x' },
          { key: '3x', sizeParam: 'w=1280&h=950&f=contain&q=100', width: '3x' },
        ];
        const url = `${this.dirIO.config.url}/${this.dirIO.config.project}/assets/`;
        [ct.photo_thumbnails, ct.photo, ct.photoType] = getSizedImages(url, arrSizes, mainTourguide.photo, false);

        //[ct.photo_thumbnails, ct.photo, ct.photoType] = getImageThumbnails(json.data.destination_owner.photo.data.thumbnails, json.data.destination_owner.photo);
      }
      ct.introText_ml = getLangObject(mainTourguide.contact_translation, 'welcome_text');
      ct.email = mainTourguide.email;
      ct.phone = mainTourguide.phone;
      ct.whatsapp = mainTourguide.whatsapp;
      ct.contactLanguages = [...mainTourguide.contact_languages];
      if (mainTourguide.street) {
        ct.address.street = mainTourguide.street;
        ct.address.zipcode = mainTourguide.zipcode;
        ct.address.city = mainTourguide.city;
        ct.address.country = mainTourguide.country;
        ct.address.coordinates = mainTourguide.address_coordinates;
      }
      contacts.push(ct);
    }
    if (contact1) {
      const ct = new Contact(contact1.id, contact1.name);
      ct.role = contact1Role;
      if (contact1.photo) {
        // get sized images so alle image have the same size
        const arrSizes = [
          { key: '1x', sizeParam: 'w=350&h=350&f=contain&q=100', width: '' },
          { key: '2x', sizeParam: 'w=800&h=600&f=contain&q=100', width: '2x' },
          { key: '3x', sizeParam: 'w=1280&h=950&f=contain&q=100', width: '3x' },
        ];
        const url = `${this.dirIO.config.url}/${this.dirIO.config.project}/assets/`;
        [ct.photo_thumbnails, ct.photo, ct.photoType] = getSizedImages(url, arrSizes, contact1.photo, false);

        //[ct.photo_thumbnails, ct.photo, ct.photoType] = getImageThumbnails(json.data.destination_owner.photo.data.thumbnails, json.data.destination_owner.photo);
      }
      ct.introText_ml = getLangObject(contact1.contact_translation, 'welcome_text');
      ct.email = contact1.email;
      ct.phone = contact1.phone;
      ct.whatsapp = contact1.whatsapp;
      ct.contactLanguages = [...contact1.contact_languages];
      if (contact1.street) {
        ct.address.street = contact1.street;
        ct.address.zipcode = contact1.zipcode;
        ct.address.city = contact1.city;
        ct.address.country = contact1.country;
        ct.address.coordinates = contact1.address_coordinates;
      }
      contacts.push(ct);
    }
    if (contact2) {
      const ct = new Contact(contact2.id, contact2.name);
      ct.role = contact2Role;
      if (contact2.photo) {
        // get sized images so alle image have the same size
        const arrSizes = [
          { key: '1x', sizeParam: 'w=350&h=350&f=contain&q=100', width: '' },
          { key: '2x', sizeParam: 'w=800&h=600&f=contain&q=100', width: '2x' },
          { key: '3x', sizeParam: 'w=1280&h=950&f=contain&q=100', width: '3x' },
        ];
        const url = `${this.dirIO.config.url}/${this.dirIO.config.project}/assets/`;
        [ct.photo_thumbnails, ct.photo, ct.photoType] = getSizedImages(url, arrSizes, contact2.photo, false);


        // [ct.photo_thumbnails, ct.photo, ct.photoType] = getImageThumbnails(contact2.photo.data.thumbnails, contact2.photo);
      }
      ct.introText_ml = getLangObject(contact2.contact_translation, 'welcome_text');
      ct.email = contact2.email;
      ct.phone = contact2.phone;
      ct.whatsapp = contact2.whatsapp;
      ct.contactLanguages = [...contact2.contact_languages];
      if (contact2.street) {
        ct.address.street = contact2.street;
        ct.address.zipcode = contact2.zipcode;
        ct.address.city = contact2.city;
        ct.address.country = contact2.country;
        ct.address.coordinates = contact2.address_coordinates;
      }
      contacts.push(ct);
    }
    return contacts;
  }

  /*
    Get cardNavigation for a property
    Cards are assigned to a specific category. This function will fetch which
    categories should be loaded in order to provide a proper navigation
    destinationId - unique ID for the property
    lng - current language
    returns   array with navigation cards (see models/navigation)
  */
  async getCardNavigation(destinationId) {
    Log.info(`getcardNavigation for destination with id:${destinationId}`, LOGMODULE);

    const param = {
      fields: 'id, icon, dest_card.all_props, dest_card.properties.destination_id, category_translation.language, category_translation.description',
      'filter[dest_card.status][eq]': 'published',
      sort: 'sort',
    };
    const json = await this.dirIO.getItems('card_categories', param);
    const navigation = [];
    let cardNav = null;
    json.data.forEach((res) => {
      let addCat = false;
      res.dest_card.forEach((card) => {
        // ... check if navigation is for this destination_id
        if (card.all_props || card.properties.findIndex(x => x.destination_id === destinationId) > -1) {
          addCat = true;
        }
      });
      if (addCat) {
        cardNav = new Navigation(res.id);
        ({
          icon: cardNav.icon = '',
        } = res);
        // cardNav.description = res.category_translation[0].description;
        cardNav.description = getLangObject(res.category_translation, 'description');
        navigation.push(cardNav);
      }
    });
    return navigation;
  }

  /*
    Get infoCards for a property
    destinationId - unique ID for the property
    category - filter only infocards for a specific category
    search - filter only infocards which contain the search string
    id - only fetch th card with the given id
    returns   array with infoCards (see models/infoCard)

    31/08 New field added all_props -> if true, card may be shown for all destinations
          !! [logical]=or does not seem to work very well, so as a work-around we will filter it client side
          !! see bug https://github.com/directus/api/issues/1402
  */
  async getInfoCards(destinationId, category, filter, id) {
    Log.info(`getInfoCards for destination with id:${destinationId}, filter on category ${category} searchstring is ${filter} infocardID is ${id}`, LOGMODULE);
    let param = {
      fields: '*, properties.destination_id, infocard_translation.*, image.*, media.*.*',
      // 'filter[properties.destination_id][eq]': destinationId, add all_props but or does not seem to work
      'filter[status][eq]': 'published',
      sort: 'sysorder',
    };
    if (id) {
      param = {
        ...param,
        'filter[id][eq]': id,
      };
    }
    if (category !== null) {
      param = {
        ...param,
        'filter[category][eq]': category,
      };
    }
    if (filter !== null) {
      param = {
        ...param,
        q: filter,
      };
    }
    const json = await this.dirIO.getItems('infocard', param);
    const infoCards = [];
    let infoCard = null;
    json.data.forEach(async (res) => {
      // ... check if the infocard should be shown for this destination
      // todo - when directus bug for or filtering is fixes, do not fetch all infocards
      if (res.all_props || res.properties.findIndex(x => x.destination_id === destinationId) > -1) {
        infoCard = new InfoCard(res.id);
        ({
          category: infoCard.category = '',
          icon: infoCard.icon = '',
        } = res);
        if (res.image) {
          // get sized images so alle image have the same size
          const arrSizes = [
            { key: '1x', sizeParam: 'w=320&h=160&f=crop&q=100', width: '' },
            { key: '2x', sizeParam: 'w=640&h=320&f=crop&q=100', width: '2x' },
          ];
          const url = `${this.dirIO.config.url}/${this.dirIO.config.project}/assets/`;
          [infoCard.image_thumbnails, infoCard.image, infoCard.imageType] = getSizedImages(url, arrSizes, res.image);
        }
        infoCard.title_ml = getLangObject(res.infocard_translation, 'title');
        infoCard.introText_ml = getLangObject(res.infocard_translation, 'introtext');
        infoCard.detail_ml = getLangObject(res.infocard_translation, 'detail');
        // Load de media files.
        res.media.forEach((elt) => {
          const mediaElt = {};
          // get sized images so alle image have the same size
          const arrSizes = [
            { key: '1x', sizeParam: 'w=350&h=350&f=contain&q=100', width: '' },
            { key: '2x', sizeParam: 'w=800&h=600&f=contain&q=100', width: '2x' },
            { key: '3x', sizeParam: 'w=1280&h=950&f=contain&q=100', width: '3x' },
          ];
          const url = `${this.dirIO.config.url}/${this.dirIO.config.project}/assets/`;
          [mediaElt.image_thumbnails, mediaElt.image, mediaElt.imageType] = getSizedImages(url, arrSizes, elt.directus_files_id, false);

          // [mediaElt.image_thumbnails, mediaElt.image, mediaElt.imageType] = getImageThumbnails(elt.directus_files_id.data.thumbnails, elt.directus_files_id);
          infoCard.media.push(mediaElt);
        });
        infoCards.push(infoCard);
      }
    });
    return infoCards;
  }
}
