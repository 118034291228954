import React from 'react';
import PlusIcon from 'mdi-react/PlusThickIcon';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const AddLineButton = ({ clickHandler }) => {
  const { t } = useTranslation('admin');

  const eltClick = (event) => {
    event.preventDefault();
    clickHandler();
  }

  return(
    <div className="admin_addLineButtonContainer">
      <button name="addButton" className="admin_button admin_button_theme" alt={t('common.addline')} data-testid="addbutton" onClick={eltClick}><PlusIcon /></button>
      <span className="typegraphy-message admin_addLineButtonText" onClick={eltClick}><strong>{t('common.addline')}</strong></span>
    </div>
  )
}

export default AddLineButton;

AddLineButton.propTypes = {
  clickHandler: PropTypes.func.isRequired
}