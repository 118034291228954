/* 
  Admin Button
  Will be showed when the logged-in user has certain roles

  Props
  I clickHandler  Handler function when button is clicked
  I roles         see below - set props in case buttons should be visible
*/
import React from 'react';
import { useSelector } from 'react-redux'
import {
  Container,
  Row,
  Col,
} from 'reactstrap';
import PencilIcon from 'mdi-react/PencilIcon';
import Log from '../../../helpers/Log/Log';
import PropTypes from 'prop-types';

const LOGMODULE = "AdminButton";

const AdminButton = (props) => {
  const { 
    clickHandler,
    adminRole,
    ownerRole,
    organisationRole,
    tourGuideRole,
    guestRole,
  } = props;
  const loggedIn = useSelector((state) => state.usr.loggedIn);
  const user = useSelector((state) => state.usr.user);

//console.log(user.IsAdminRole);
  // ... if not logged in - no show
  if (!loggedIn) {
    return null;
  }
  // ... chech the roles
  if (
    (adminRole && user.isAdminRole) ||
    (ownerRole && user.isOwnerRole) ||
    (organisationRole && user.isOrganisationRole) ||
    (tourGuideRole && user.isTourGuideRole) ||
    (guestRole && user.isGuestRole)
    ) {
    Log.trace('Render', LOGMODULE);
    return (
      <Container className="dashboard">
        <Row>
          <Col xs={12} md={12} lg={8}>
            <div className="admin_floatingContainer">
              <button className="admin_button_large admin_button_theme" onClick={clickHandler} data-testid="adminbutton"><PencilIcon /></button>
            </div>
          </Col>
        </Row>
      </Container>
    );
  } else {
    return null;
  }
}

AdminButton.propTypes ={
  clickHandler: PropTypes.func.isRequired,
  adminRole: PropTypes.bool,
  ownerRole: PropTypes.bool,
  organisationRole: PropTypes.bool,
  tourGuideRole: PropTypes.bool,
  guestRole: PropTypes.bool,
}
AdminButton.defaultProps ={
  adminRole: false,
  ownerRole: false,
  organisationRole: false,
  tourGuideRole: false,
  guestRole: false,
}

export default AdminButton;
