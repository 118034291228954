/* eslint-disable prefer-template */
/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable operator-linebreak */
import React from 'react';
import EmailIcon from '@material-ui/icons/AlternateEmail';
import PhoneIcon from '@material-ui/icons/Phone';
import SmsIcon from '@material-ui/icons/Sms';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const formatPhoneNumber = (data) => {
  let link = null;
  try {
    link = parsePhoneNumberFromString(data, 'BE').number;
    return link;
  } catch {
    return null;
  }
};

const phoneLink = ({ type, linkData, linkAddress, linkBody }) => {
  let jsx = null;
  if (!linkAddress && !linkBody) {
    return null;
  }
  let link = '';
  let linkContent = null;
  if (linkData) {
    linkContent = ` ${linkData}`;
  }
  switch (type) {
    case 'PHONE':
      link = 'tel:' + formatPhoneNumber(linkAddress);
      jsx = <a href={link}><PhoneIcon fontSize="inherit" />{linkContent}</a>;
      break;
    case 'SMS':
      link = 'sms:' + formatPhoneNumber(linkAddress);
      if (linkBody) {
        link += '?body=' + encodeURIComponent(linkBody);
      }

      jsx = <a href={link}><SmsIcon fontSize="inherit" />{linkContent}</a>;
      break;
    case 'EMAIL':
      link = 'mailto:' + linkAddress;
      if (linkBody) {
        link += '?body=' + encodeURIComponent(linkBody);
      }

      jsx = <a href={link}><EmailIcon fontSize="inherit" />{linkContent}</a>;
      break;
    case 'WHATSAPP':
      link = 'https://wa.me/';
      if (linkData) {
        link += formatPhoneNumber(linkAddress);
      }
      if (linkBody) {
        link += '?text=' + encodeURIComponent(linkBody);
      }
      jsx = <a href={link}><WhatsAppIcon fontSize="inherit" />{linkContent}</a>;
      break;
    default:
      jsx = linkData;
  }
  return jsx;
};

export default phoneLink;
