import React from 'react';
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

const NotFound404 = (props) => {
  const { t } = props;

  // ... recuperate links without tenant and alias.
  //  / (or home) -> check localstorage for a basePath (app was loaded before) -> redirect using old basePath
  //  /contact or /infocards ... in other words any of the valid GuestHouse Routes -> in that case we load the
  //  basePath from the cach and rerout using the full url (this happens if the back-end user is )

  let { message } = props;
  const cachedBasePath = localStorage.getItem('basePath');
  if (cachedBasePath) {
    const urlPath = window.location.pathname;
    if (!urlPath.startsWith(cachedBasePath) && !message) {
      return (<Redirect to={`${cachedBasePath}${urlPath}`} />);
      // window.location.replace(`${cachedBasePath}${urlPath}`);
    }
  } else if (window.location.pathname === '/') {
    return (<Redirect to="/init" />);
  }
  if (!message) {
    message = t('navigation.404');
  }
  return (
    <div className="not-found">
      <div className="not-found__content">
        <h3 className="not-found__info">{message}</h3>
      </div>
    </div>
  );
};

export default withTranslation(['common'])(NotFound404);
