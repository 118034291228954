/*
  13/08 - bugfix ... error - continuous loop while fetching a specific infocard
  01/09 - Add media carrousel, remove intro image
  01/09 - Refactored to functional  componnt and use of Hooks
  10/09 - Component can be loaded by itselves (from route), but also as component in list
          In that case props.infoCardId is passed !
*/
/* eslint-disable eqeqeq */
/* eslint-disable react/no-unused-state */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable arrow-parens */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prefer-stateless-function */
import React, { useState, useEffect } from 'react';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardImg,
  CardText,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import querystring from 'querystring';
import Carousel from './Components/InfoCardDetailMediaCarousel';
import InfoCardDetailComponent from './Components/InfoCardDetailComponent';
import { infoCardsFetch } from '../../../redux/actions/index';
import Loading from '../../../shared/components/loading/loading';
import Alert from '../../../shared/components/Alert';
import CardDetailTopBar from '../../../shared/components/cardDetailTopNav/cardDetailTopNav';
import Log from '../../../helpers/Log/Log';

const LOGMODULE = 'infocardDetail';

const infoCardDetail = (props) => {
  Log.info('render', LOGMODULE);
  const { t } = props;
  const { id = null } = props.match.params;
  const { returnUrl: navBack = null } = querystring.parse(props.location.search.slice(1));
  return <InfoCardDetailComponent infoCardId={id} navBack={navBack} />;
};

export default withTranslation(['guesthouse', 'common'])(infoCardDetail);
