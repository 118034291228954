/* eslint-disable object-shorthand */
/* eslint-disable no-underscore-dangle */
import {
  combineReducers,
  createStore,
  applyMiddleware,
  compose,
} from 'redux';
// add-persist: import { persistStore, persistReducer } from 'redux-persist';
// add-persist: import storage from 'redux-persist/lib/storage';
// add-persist: import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import thunk from 'redux-thunk';
import {
  sidebarReducer,
  themeReducer,
  rtlReducer,
  guestHouseReducer,
  destinationReducer,
  contactsReducer,
  infoCardsReducer,
  programReducer,
  userReducer,
} from './reducers/index';

/* // add-persist:
const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['gh'],
  stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
};
*/

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const reducer = combineReducers({
  theme: themeReducer,
  sidebar: sidebarReducer,
  rtl: rtlReducer,
  gh: guestHouseReducer,
  dest: destinationReducer,
  ctct: contactsReducer,
  ifc: infoCardsReducer,
  pg: programReducer,
  usr: userReducer,
});
// add-persist: const pReducer = persistReducer(persistConfig, reducer);

// add-persist: export const store = createStore(pReducer, composeEnhancers(applyMiddleware(thunk)));
// add-persist: export const persistor = persistStore(store);
const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));

export default store;
