/*
  guestHouseRoutes      Noël Thoelen 17/10/2020
  This component will load all routes in the app to navigate for a guesthouse
  This will based on the url
    /tenant/alias/...
  If no tenant or alias are passed (so home page) we will load basepath from localstorage
  In that way, the last viewed guesthouse is shown.
  At first load, we will check if the tenant ans alias ID are valid and if so, load some redux state
*/
import React, { Fragment, useEffect, Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, withRouter, Switch, useParams } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Loading from '../../shared/components/loading/loading';

// import LogIn from '../LogIn/index';
import GuestHouseWrapper from '../GuestHouse/guestHouseWrapper';
import { guestHouseAppStart } from '../../redux/actions/index';
import WelcomePage from '../GuestHouse/Welcome/index';
import ContactPage from '../GuestHouse/Contact/index';
import InfoCardList from '../GuestHouse/InfoCards/InfoCardList';
import InfoCardDetail from '../GuestHouse/InfoCards/InfoCardDetail';
import ProgramDetail from '../GuestHouse/Program/ProgramDetail';
import LoginForm from '../LogIn/LoginForm';

import Page404 from '../Blank/404';
import Log from '../../helpers/Log/Log';

const LOGMODULE = 'routerGuestHouse';

const RouterGuestHouse = (props) => {
  const dispatch = useDispatch();
  const { tenant, alias, tripcode } = useParams();
  const { host } = window.location;
  const basePath = useSelector((state) => state.gh.basePath);
  const error = useSelector((state) => state.gh.error);
  const invalid = useSelector((state) => state.gh.invalid);
  const { t } = props;

  Log.info('Render', LOGMODULE);

  // ... at first load, check if tenant and alias are valid, if not, we redirect to the init page
  useEffect(() => {
    Log.info('useEffect - start App load', LOGMODULE);
    dispatch(guestHouseAppStart(host, tenant, alias, tripcode));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let routes = null;
  if (error) {
    return (
      <Page404 message={t('init.loaderror')} />
    );
  }
  if (invalid) {
    return (
      <Page404 message={t('init.invalid')} />
    );
  }
  if (basePath) {
    routes = (
      <GuestHouseWrapper>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route exact path={`${basePath}/login`} component={LoginForm} />
            <Route exact path={`${basePath}/welcome`} component={WelcomePage} />
            <Route exact path={`${basePath}/contact`} component={ContactPage} />
            <Route exact path={`${basePath}/infocards/:category`} component={InfoCardList} />
            <Route exact path={`${basePath}/infocards`} component={InfoCardList} />
            <Route exact path={`${basePath}/infocard/:id`} component={InfoCardDetail} />
            <Route exact path={`${basePath}/program/:programDate`} component={ProgramDetail} />
            <Route exact path={`${basePath}/`} component={WelcomePage} />
            
            <Route component={Page404} />
          </Switch>
        </Suspense>
      </GuestHouseWrapper>
    );
  }
  return (
    <Fragment>
      {routes}
    </Fragment>
  );
};

/*
const mapStateToProps = (state) => {
  return {
    destinationId: state.gh.destinationId,
    appStarted: state.gh.appStarted,
    supportedLngs: state.gh.supportedLngs,
    fallBackLng: state.gh.fallBackLng,
    invalid: state.gh.invalid,
    reinitialized: state.gh.reinitialized,
    error: state.gh.error,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onGuestHouseAppLoad: () => dispatch(actions.guestHouseAppLoad()),
  };
};

// ! withRouter is needed. Otherwise this component will not rerender if the route changes
// It seems it has to be done here ...
// It will also make sure the child components will rerender
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation(['common'])(Router)));
*/
export default withRouter(withTranslation(['guesthouse', 'common'])(RouterGuestHouse));
