
import SquidexDataController from './squidexController';
import DirectusController from './directus/directusController';
import ProgramController from './directus/programController';
import UserController from './directus/userController';

export class InvalidGuestHouseError extends Error {}
export class InvalidUserError extends Error {}
export class InvalidUserCredentials extends Error {}
export class UserInactiveError extends Error {}
export class UserSuspendedError extends Error {}
export class SessionExpiredError extends Error {}

export function getDataController(tenant) {
  let ctl = null;
  if (process.env.REACT_APP_CMS === 'Squidex') {
    ctl = new SquidexDataController();
  } else {
    ctl = new DirectusController(tenant);
  }
  return ctl;
}

export function getProgramController(tenant) {
  let ctl = null;
  if (process.env.REACT_APP_CMS === 'Squidex') {
    ctl = new SquidexDataController();
  } else {
    ctl = new ProgramController(tenant);
  }
  return ctl;
}

export function getUserController(tenant) {
  let ctl = null;
  if (process.env.REACT_APP_CMS === 'Squidex') {
    ctl = new SquidexDataController();
  } else {
    ctl = new UserController(tenant);
  }
  return ctl;
}
