import { getProgramController } from '../../redux/controller/dataController';
import Log from '../../helpers/Log/Log';

// import { programsMock, programLinesMock } from '../models/_mocks/program';

export const PROGRAMDETAIL_FETCH_START = 'PROGRAMDETAIL_FETCH_START';
export const PROGRAMDETAIL_FETCH_SUCCESS = 'PROGRAMDETAIL_FETCH_SUCCESS';
export const PROGRAMDETAIL_FETCH_FAIL = 'PROGRAMDETAIL_FETCH_FAIL';
export const PROGRAMDETAIL_UPDATE_START = 'PROGRAMDETAIL_UPDATE_START';
export const PROGRAMDETAIL_LINESUPDATE = 'PROGRAMDETAIL_LINESUPDATE';
export const PROGRAMDETAIL_UPDATE = 'PROGRAMDETAIL_UPDATE';
export const PROGRAMDETAIL_SAVE_ERR = 'PROGRAMDETAIL_SAVE_ERR';

function programDetailFetchStart() {
  return {
    type: PROGRAMDETAIL_FETCH_START,
  };
}

function programDetailFetchSuccess(programs, programLines) {
  return {
    type: PROGRAMDETAIL_FETCH_SUCCESS,
    programs: programs,
    programLines: programLines,
  };
}

function programDetailFetchFail(err) {
  return {
    type: PROGRAMDETAIL_FETCH_FAIL,
    error: err,
  };
}

function programDetailUpdateStart() {
  return {
    type: PROGRAMDETAIL_UPDATE_START,
  };
}


function programDetailLinesUpdate(payload) {
  return {
    type: PROGRAMDETAIL_LINESUPDATE,
    payload: payload,
  }
}

function programDetailUpdate(payload) {
  return {
    type: PROGRAMDETAIL_UPDATE,
    payload: payload,
  }
}


function programDetailSaveErr(err) {
  return {
    type: PROGRAMDETAIL_SAVE_ERR,
    payload: err,
  }
}

// thunk functions
export const programDetailFetch = (tenant, tripcode, date, tourGuideId = null, dayNbr = null) => {
  return (dispatch) => {
    dispatch(programDetailFetchStart());

    const datactl = getProgramController(tenant);

    datactl.getProgramDetail(tripcode, date, tourGuideId, dayNbr)
      .then(([programs, programLines]) => {
        Log.trace(date, 'programDetailFetch');
        sortLines(programLines);
        dispatch(programDetailFetchSuccess(programs, programLines));
      })
      .catch((error) => {
        Log.error(error, 'programDetailFetch');
        dispatch(programDetailFetchFail(error));
      });

  /* mock data
  sortLines(programLinesMock);
  dispatch(programDetailFetchSuccess(programsMock, programLinesMock));
  */
  };
};

/* update/add a program line
  Because, we loaded a template, we will allways save all lines for this tourguide
  These lines of the template are already stored in the state
  In the future, this behavior can be changed if we save the template server-side
  I ProgramLine : which line is updated
                  -1 new line
                  x line (starts with 1)
  I ProgDayLine see model ProgramDayLine
  return  null = Ok
          error message 
*/
export const programLineSaveThunk = (programLine, progDayLine, published) => {
  return async (dispatch, getState) => {
    dispatch(programDetailUpdateStart());
    try {
      const prevState = getState();
      const lines = [...prevState.pg.programLines]
      const tenant = prevState.gh.tenant;
      const tripcode = prevState.gh.tripcode;
      const date = prevState.pg.programs[0].date;
      const tourGuideId = prevState.usr.user.contactId;

      // ... build the array with lines to be saved
      // update the state
      if (programLine === -1) {
        lines.push(progDayLine)        
      } else {
        lines[programLine - 1] = progDayLine;
      }
      sortLines(lines);

      // save to the DB
      const datactl = getProgramController(tenant);
      await datactl.saveProgram(tripcode, date, tourGuideId, lines, published);

      dispatch(programDetailLinesUpdate(lines));
      return true;
    } catch (error) {
      dispatch(programDetailSaveErr(error.message));
      return error;
    }
  }
}

/* delete a program line
  Because, we loaded a template, we will allways save all lines for this tourguide
  These lines of the template are already stored in the state
  In the future, this behavior can be changed if we save the template server-side
  I ProgramLine : which line needs to be deleted
                  x line (starts with 1)
  return  null = Ok
          error message 
*/
export const programLineDeleteThunk = (programLine, progDayLine) => {
  return async (dispatch, getState) => {
    dispatch(programDetailUpdateStart());
    try {
      const prevState = getState();
      const tenant = prevState.gh.tenant;
      const tripcode = prevState.gh.tripcode;
      const tourGuideId = prevState.usr.user.contactId;
      const prog = prevState.pg.programs.find(elt => elt.programId === progDayLine.programId);

      const lines = [...prevState.pg.programLines]
      lines.splice(programLine - 1, 1);
      const datactl = getProgramController(tenant);
      await datactl.saveProgram(tripcode, prog.date, tourGuideId, lines, prog.published);
      
      dispatch(programDetailLinesUpdate(lines));
      return true;
    } catch (error) {
  console.log(error);
      dispatch(programDetailSaveErr(error.message));
      return error;
    }
  }
}

/*
  Delete all lines for a tourguide
  We will blank all lines
*/
export const programDeleteThunk = () => {
  return async (dispatch, getState) => {
    dispatch(programDetailUpdateStart());
    try {
      const prevState = getState();
      const tenant = prevState.gh.tenant;
      const tripcode = prevState.gh.tripcode;
      const date = prevState.pg.programs[0].date;
      const tourGuideId = prevState.usr.user.contactId;
      const lines = prevState.pg.programLines.filter(elt => elt.editable === false);

      const datactl = getProgramController(tenant);
      await datactl.saveProgram(tripcode, date, tourGuideId, lines, true);
      dispatch(programDetailLinesUpdate(lines));
      return true;

    } catch (error) {
      dispatch(programDetailSaveErr(error.message));
      return error;
    }
  }

}

/*
  change published state for a program
  This will be changed for all programs for the current tourguide
*/
export const programSetPublishedState = (newState) => {
  return async (dispatch, getState) => {
    dispatch(programDetailUpdateStart());
    try {
      const prevState = getState();
      const tenant = prevState.gh.tenant;
      const tripcode = prevState.gh.tripcode;
      const progs = [...prevState.pg.programs];
      const tourGuideId = prevState.usr.user.contactId;
      const lines = prevState.pg.programLines;
      const date = progs[0].date;
      progs.forEach(pg => {
        if (pg.tourguideId == tourGuideId) {
          pg.published = newState;
        }
      });

      const datactl = getProgramController(tenant);
      await datactl.saveProgram(tripcode, date, tourGuideId, lines, newState);
      dispatch(programDetailUpdate(progs));
      return true;

    } catch (error) {
      dispatch(programDetailSaveErr(error.message));
      return error;
    }
  }
}

function sortLines(progLines) {
  progLines.sort(function(a,b) {
    if (!a.hour) {
      return -1
    }
    if (a.hour < b.hour) {
      return -1
    } else {
      return 1
    }
  });
}

