/* eslint-disable max-len */
/* eslint-disable no-lonely-if */
/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
/* eslint-disable arrow-parens */
/* eslint-disable object-curly-newline */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import AddressWithMap from '../../../shared/components/Address/AddressWithMap';
import CardMedia from '../../../shared/components/cardMedia/CardMedia';
import Loading from '../../../shared/components/loading/loading';
import Alert from '../../../shared/components/Alert';
import * as actions from '../../../redux/actions/index';
import Log from '../../../helpers/Log/Log';

const LOGMODULE = 'welcome';

// eslint-disable-next-line react/prefer-stateless-function
class WelcomePage extends Component {
  componentDidMount() {
    Log.info('componentDidMount', LOGMODULE);
    Log.trace(`fetch data for property ${this.props.destinationId} in tenant ${this.props.tenant}`, LOGMODULE);
    this.props.onDestinationFetch(this.props.tenant, this.props.destinationId);
  }

  render() {
    Log.info('render', LOGMODULE);
    const { t } = this.props;
    const { language } = this.props.i18n;
    const { fallBackLng } = this.props;

    let jsx = <Loading />;
    if (!this.props.loading) {
      if (this.props.destination !== null) {
        let addressBlock = null;
        if (this.props.destination.address) {
          addressBlock = (
            <Row>
              <Col xs={12} md={12} lg={8}>
                <Card>
                  <CardBody>
                    <h5 className="bold-text">{t('welcome.location')}</h5>
                    <AddressWithMap
                      coordinates={this.props.destination.address.coordinates}
                      name={this.props.destination.name}
                      street={this.props.destination.address.street}
                      zipcode={this.props.destination.address.zipcode}
                      city={this.props.destination.address.city}
                      country={this.props.destination.address.country}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          );
        }
        jsx = (
          <Container className="dashboard">
            <Row>
              <Col xs={12} md={12} lg={8}>
                <h3 className="page-title">{this.props.destination.name}</h3>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={8}>
                <Card>
                  <CardBody>
                    <CardMedia
                      mediaType={this.props.destination.destinationImageType}
                      imageSrcSet={this.props.destination.getImageSrcSet()}
                      mediaUrl={this.props.destination.destinationImage}
                      mediaAltText={t('welcome.altimage')}
                    />
                    <p
                      className="typography-message"
                      // eslint-disable-next-line
                      dangerouslySetInnerHTML={{ __html: this.props.destination.getIntroText(language, fallBackLng) }}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {addressBlock}
          </Container>
        );
      } else {
        if (this.props.errMsg !== '') {
          jsx = (
            <Alert className="alert-colored" color="danger" icon>
              <p><span className="bold-text">Error!</span>{t('welcome.loaderror', { propid: this.props.destinationId })}
              </p>
            </Alert>
          );
        }
      }
    }
    return jsx;
  }
}

const mapStateToProps = (state) => {
  return {
    tenant: state.gh.tenant,
    fallBackLng: state.gh.fallBackLng,
    destinationId: state.gh.destinationId,
    destination: state.dest.destination,
    loading: state.dest.loading,
    errMsg: state.dest.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDestinationFetch: (tenant, id) => dispatch(actions.destinationFetch(tenant, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['guesthouse', 'common'])(WelcomePage));
