import {
  PROGRAMDETAIL_FETCH_START,
  PROGRAMDETAIL_FETCH_SUCCESS,
  PROGRAMDETAIL_FETCH_FAIL,
  PROGRAMDETAIL_UPDATE_START,
  PROGRAMDETAIL_LINESUPDATE,
  PROGRAMDETAIL_UPDATE,
  PROGRAMDETAIL_SAVE_ERR,
} from '../actions/programActions';

const initialState = {
  programs: null,           // see programDay model
  programLines: null,       // see progrmDayLine model
  loading: true,
  error: '',
  saveError: '',
};

export default function programReducer(state = initialState, action) {
  switch (action.type) {
    case PROGRAMDETAIL_FETCH_START:
      return {
        ...state,
        loading: true,
        programs: null,           // array of programs -> see programDay model
        programLines: null,       // array of programLines -> see programDayLine model
        error: '',
        saveError: '',
      };
    case PROGRAMDETAIL_FETCH_SUCCESS:
      return {
        ...state,
        programs: [...action.programs],
        programLines: [...action.programLines],
        loading: false,
        error: '',
        saveError: '',
      };
    case PROGRAMDETAIL_FETCH_FAIL:
      return {
        ...state,
        loading: false,
        programs: null,
        programLines: null,
        error: action.error,
      };
    case PROGRAMDETAIL_UPDATE_START:
      return {
        ...state,
        loading: true,
        saveError: '',
      };  
    case PROGRAMDETAIL_LINESUPDATE:
      return {
        ...state,
        loading: false,
        saveError: '',
        programLines: [...action.payload],
      }
    case PROGRAMDETAIL_UPDATE:
      return {
        ...state,
        loading: false,
        saveError: '',
        programs: [...action.payload],
      }
    case PROGRAMDETAIL_SAVE_ERR:
      return {
        ...state,
        loading: false,
        saveError: action.payload,
      }
    
    default:
      return state;
  }
}
