export default class User {
  constructor() {
    this.loginId = '';            // loginId
    this.contactId = ''           // contact Id 
    this.userName = '';
    this.email = ''
    this.photoUrl = '';
    this.isAdminRole = false;
    this.isOwnerRole = false;
    this.isOrganisationRole = false;
    this.isTourGuideRole = false;
    this.isGuestRole = false;
  }
  
}