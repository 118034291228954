/* eslint-disable no-else-return */
export default class Contact {
  constructor(id, name) {
    this.contactID = id;
    this.name = name;
    this.role = '';
    this.photo = '';
    this.photoType = '';
    this.photo_thumbnails = [];
    this.introText_ml = [];
    this.email = '';
    this.phone = '';
    this.whatsapp = '';
    this.contactLanguages = [];
    this.address = {
      street: '',
      zipcode: '',
      city: '',
      country: '',
      coordinates: null,
    };
  }

  getIntroText(lng, fallBackLng) {
    if (lng in this.introText_ml) {
      return this.introText_ml[lng];
    }
    return this.introText_ml[fallBackLng];
  }

  getImageSrcSet() {
    if (typeof this.photo_thumbnails !== 'undefined') {
      const srcSet = this.photo_thumbnails.map((element) => (`${element.url} ${element.width}`));
      return srcSet.toString();
    } else {
      return '';
    }
  }
}
