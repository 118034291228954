/* functions to maintain localstorage */

const LOGINID = 'loginId';        // userId of user who did login
const LOGINEMAIL = 'email';       // email address -> in case rememberMe is set in loginform

export function saveLogin (loginId) {
  localStorage.setItem(LOGINID, loginId);
}

export function getLogin () {
  return localStorage.getItem(LOGINID);
}

export function resetLogin () {
  localStorage.removeItem(LOGINID);
}

export function saveLoginEmail (email) {
  if (email) {
    localStorage.setItem(LOGINEMAIL, email);
  } else {
    localStorage.removeItem(LOGINEMAIL);
  }
}

export function getLoginEmail() {
  return localStorage.getItem(LOGINEMAIL);
}


