/*
  we also keep some extra info for each line
    - editable - only true if the current logged in Tourguide is the owner
    - row - needed for updating -> which row line (only for logged in tourguide)
*/
export default class programDayLine {
  constructor(programId, rowId, editable, hour, title, location, detail) {
    this.rowId = rowId;                     // null for new lines else unique key
    this.programId = programId;
    this.editable = editable;               // is this line of the current user( tourguide) ?
    if (hour) {
      this.hour = hour.substring(0,5);
    } else {
      hour = '';
    }
    this.title = title;
    this.location = location;
    this.detail = detail;
  }
}