import DirectusSDK from '@directus/sdk-js';
import Log from '../../../helpers/Log/Log';

const LOGMODULE = 'directusIO';
// use JWT tokens as work around for debugging production issues
// uncomment all use JWT tokens comment lines
// see : https://github.com/directus/v8-archive/issues/1385
//#use JWT tokens# let loginToken = ''

export default class DirectusIO {
  constructor(tenant) {
    this.config = {
      url: process.env.REACT_APP_CMS_URL,
      project: tenant,
      token: process.env.REACT_APP_DIRECTUS_KEY,
      mode: 'cookie',
    }
  }

  async login(email, password) {
    const client = new DirectusSDK({
      url: this.config.url,
      project: this.config.project,
      mode: 'cookie',
      //#use JWT tokens# mode: 'jwt'
    });
    Log.info(`login: project:${this.config.project} email:${email}`, LOGMODULE);
    const res = await client.login({
      email: email,
      password: password,
    });
    //#use JWT tokens# loginToken = res.data.token;
    return res;  
  }

  logout() {
    const client = new DirectusSDK({
      url: this.config.url,
      project: this.config.project,
      mode: 'cookie',
    });
    Log.info(`logout: project:${this.config.project}`, LOGMODULE);
    client.logout();
  }

    /* === Private function */
  /* GetItems - query Directus with correct filters etc
    I collection  name of the collection
    I qparams     query parameters to pass
                  see Directus documentation for correct documentation
    Returns       json with the results
  */
 async getItems(collection, qparams) {
  const client = new DirectusSDK(this.config);
  Log.info(`getItems: collection:${collection} app:${this.config.project}`, LOGMODULE);
  const res = await client.getItems(collection, qparams);
  Log.info(res, LOGMODULE);
  return res;
}

 async getProtectedItems(collection, qparams) {
  const client = new DirectusSDK({
    url: this.config.url,
    project: this.config.project,
    mode: 'cookie',
    //#use JWT tokens# mode: 'jwt',
    //#use JWT tokens# token: loginToken
  });
  Log.info(`getProtectedItems: collection:${collection} app:${this.config.project}`, LOGMODULE);
  const res = await client.getItems(collection, qparams);
  Log.info(res, LOGMODULE);
  return res;
  }
  
  /* GetItem - use the Directus getItem to fetch data for a given id
    I collection  name of the collection to fetch the info for
    I id          id of the item to fetch
    I qparams     query parameters to pass
                  which fields to return ....
                  see Directus documentation for correct documentation
    Returns   Array of items passed the filter statement specified
  */
  async getItem(collection, id, qparams) {
    const client = new DirectusSDK(this.config);
    Log.info(`getitem: collection:${collection}, id:${id}`, LOGMODULE);
    const res = await client.getItem(collection, id, qparams);
    Log.info(res, LOGMODULE);
    return res;
  }

  /* CreateItem - use the Directus getItem to fetch data for a given id
    I collection  name of the collection to fetch the info for
    I body        id of the item to fetch
    Returns   result of created item
  */
  async createProtectedItem(collection, body) {
    const client = new DirectusSDK({
      url: this.config.url,
      project: this.config.project,
      mode: 'cookie',
      //#use JWT tokens# mode: 'jwt',
      //#use JWT tokens# token: loginToken
    });
    Log.info(`createProtectedItem: collection:${collection} app:${this.config.project}`, LOGMODULE);
    const res = await client.createItem(collection, body)
    Log.info(res, LOGMODULE);
    return res;
  }

  /* UpdateItem - use the Directus getItem to fetch data for a given id
    I collection  name of the collection to fetch the info for
    I id          Id of record to be updated
    I body        id of the item to fetch
    Returns   result of created item
  */
    async updateProtectedItem(collection, id, body) {
      const client = new DirectusSDK({
        url: this.config.url,
        project: this.config.project,
        mode: 'cookie',
        //#use JWT tokens# mode: 'jwt',
        //#use JWT tokens# token: loginToken
      });
      Log.info(`updateProtectedItem: collection:${collection} id:${id} app:${this.config.project}`, LOGMODULE);
      const res = await client.updateItem(collection, id, body)
      Log.info(res, LOGMODULE);
      return res;
    }
  
}