/* eslint-disable eqeqeq */
/* eslint-disable react/no-unused-state */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable arrow-parens */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prefer-stateless-function */
import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import querystring from 'querystring';
import * as actions from '../../../redux/actions/index';
import Loading from '../../../shared/components/loading/loading';
import Alert from '../../../shared/components/Alert';
import InfoCardListItem from './Components/InfoCardListItem';
import InfoCardDetailComponent from './Components/InfoCardDetailComponent';
import CardTopBar from '../../../shared/components/cardTopNav/cardTopNav';
import Log from '../../../helpers/Log/Log';

const LOGMODULE = 'infocardlist';

class InfoCardList extends Component {
  componentDidMount() {
    Log.info('componentDidMount', LOGMODULE);
    this.checkFiltersChanged();
  }

  componentDidUpdate() {
    Log.info('componentDidUpdate', LOGMODULE);
    this.checkFiltersChanged();
  }

  checkFiltersChanged = () => {
    if (this.props.destinationId !== '') {
      // ... url can conatin the following:
      //  infocards/category -> filter oly cards for that category
      //  ?search=ss -> search on ss
      const { category = null } = this.props.match.params;
      const { search: filter = null } = querystring.parse(this.props.location.search.slice(1));
      Log.trace(`checkFiltersChanged - category ${category} =? ${this.props.loadedCategory} - search ${filter} =? ${this.props.loadedSearchFilter}`, LOGMODULE);
      if (category !== this.props.loadedCategory || filter !== this.props.loadedSearchFilter) {
        Log.trace(`checkFilterChanged: fetch infocards for - category ${category} - search ${filter}`, LOGMODULE);
        this.props.onInfoCardsFetch(this.props.tenant, this.props.destinationId, category, filter);
      }
    }
  }

  onSearchRequest = (searchString) => {
    Log.trace(`Start search ${searchString}`, LOGMODULE);
    this.props.history.push(`${this.props.basePath}/infocards?search=${searchString}`);
  }

  onCardClickHandler = (cardId) => {
    Log.trace(`onCardClickHandler for infoCard ${cardId}`, LOGMODULE);
    this.props.history.push(`${this.props.basePath}/infocard/${cardId}?returnUrl=${this.props.location.pathname}`);
  }

  render() {
    Log.info('render', LOGMODULE);
    const { t } = this.props;
    const { language } = this.props.i18n;
    const { fallBackLng } = this.props;
    if (this.props.loading) {
      Log.trace('render loading', LOGMODULE);
      return <Loading />;
    }
    if (this.props.error !== '') {
      Log.error(`error occurred in loading Infocards: ${this.props.error}`, LOGMODULE);
      return (
        <Alert color="danger" icon>
          <p>{t('infocard.loaderror')}</p>
        </Alert>
      );
    }
    let cardResults = null;
    switch (this.props.infoCards.length) {
      case 0:
        Log.trace('render no infoCards - array is empty', LOGMODULE);
        cardResults = (
          <Alert color="info" icon>
            <p>{t('infocard.noinfo')}</p>
          </Alert>
        );
        break;
      case 1:
        Log.trace('render infocard detail - only 1', LOGMODULE);
        cardResults = (
          <InfoCardDetailComponent infoCardId={this.props.infoCards[0].infoCardID} navBack={null} />
        );
        break;
      default:
        Log.trace('render infoCards - more than 1', LOGMODULE);
        cardResults = this.props.infoCards.map(infoCard => (
          <Col xs={12} sm={12} md={6} lg={4} xl={3} key={infoCard.infoCardID}>
            <InfoCardListItem
              infoCardID={infoCard.infoCardID}
              title={infoCard.getTitle(language, fallBackLng)}
              introText={infoCard.getIntroText(language, fallBackLng)}
              imageType={infoCard.imageType}
              image={infoCard.image}
              imageSrcSet={infoCard.getImageSrcSet()}
              icon={infoCard.icon}
              clicked={() => this.onCardClickHandler(infoCard.infoCardID)}
            />
          </Col>
        ));
    }
    let jsx = null;
    Log.trace('render infocards', LOGMODULE);
    let search = this.props.loadedSearchFilter;
    let nextCategory = null;
    let previousCategory = null;
    let categoryTitle = t('infocard.searchresults');
    if (this.props.loadedCategory !== null) {
      search = null;
      const i = this.props.cardCategories.findIndex(x => x.Id === this.props.loadedCategory);
      if (i !== -1) {
        categoryTitle = this.props.cardCategories[i].getDescription(language, fallBackLng);
        if (i > 0) {
          previousCategory = {
            title: this.props.cardCategories[i - 1].getDescription(language, fallBackLng),
            route: `${this.props.basePath}/InfoCards/${this.props.cardCategories[i - 1].Id}`,
          };
        }
        if (i < this.props.cardCategories.length - 1) {
          nextCategory = {
            title: this.props.cardCategories[i + 1].getDescription(language, fallBackLng),
            route: `${this.props.basePath}/InfoCards/${this.props.cardCategories[i + 1].Id}`,
          };
        }
      }
    }
    jsx = (
      <>
        <Container className="dashboard">
          <Row>
            <Col md={12}>
              <CardTopBar
                title={categoryTitle}
                previousCat={previousCategory}
                nextCat={nextCategory}
                searchString={this.props.loadedSearchFilter}
                search={this.onSearchRequest}
              />
            </Col>
          </Row>
          <Row>
            {cardResults}
          </Row>
        </Container>
      </>
      // ))
    );
    return jsx;
  }
}

const mapStateToProps = (state) => ({
  tenant: state.gh.tenant,
  fallBackLng: state.gh.fallBackLng,
  destinationId: state.gh.destinationId,
  basePath: state.gh.basePath,
  cardCategories: state.gh.navigation,
  infoCards: state.ifc.infoCards,
  loading: state.ifc.loading,
  loadedCategory: state.ifc.loadedCategory,
  loadedSearchFilter: state.ifc.loadedSearchFilter,
  error: state.ifc.error,
});

const mapDispatchToProps = (dispatch) => ({
  onInfoCardsFetch: (tenant, destinationId, category, filter) => dispatch(actions.infoCardsFetch(tenant, destinationId, category, filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['guesthouse', 'common'])(InfoCardList));
