import ReactGA from 'react-ga';

const TRACKING_ID = 'UA-181476792-1' //'UA-176119880-1';
const trackers = ['MTB'];

function init() {
  // Enable debug mode on the local development environment
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
  // Enable test mode if not on production
  const isProd = process.env.NODE_ENV === 'production';
  //const isProd = true;
console.log('ga-initialize - test - production', isDev, isProd)
  ReactGA.initialize(TRACKING_ID, { name: 'MTB', debug: isDev, testMode: !isProd });
}

function sendEvent(payload) {
  ReactGA.event(payload, trackers);
}
function sendException(message) {
  ReactGA.exception({
    description: message,
  });
}
function addTenantTracker(tenantTrackerId) {
  ReactGA.addTrackers(
    [
      {
        trackingId: tenantTrackerId,
        gaOptions: { name: 'tenant' }
      }
    ]
  );
  trackers.push('tenant');
}

function sendPageview(path) {
console.log('ga-pageview', path)
  ReactGA.set({ page: path });
  ReactGA.pageview(path, trackers);
}

function setTrackStatistical(option) {
  ReactGA.set({ anonymizeIp: !option }, trackers);
}

function setTrackAds(option) {
  ReactGA.set({ allowAdFeatures: option }, trackers);
}

export {
  init,
  sendEvent,
  addTenantTracker,
  sendException,
  sendPageview,
  setTrackStatistical,
  setTrackAds,
};
