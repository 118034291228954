/* eslint-disable arrow-body-style */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/button-has-type */
import React from 'react';
import Log from '../../helpers/Log/Log';
import SharePage from '../../shared/components/sharePage/sharePage';

const LOGMODULE = 'guestHouseWrapper';

const guestHouseWrapper = ({ children }) => {
  Log.info('render', LOGMODULE);
  return (
    <div>
      {children}
      <SharePage />
    </div>
  );
};

export default guestHouseWrapper;
