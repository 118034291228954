import React, { Component } from 'react';
import { hot } from 'react-hot-loader';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../scss/app.scss';
import Router from './Router';
import store from '../../redux/store';
import ReactPWAInstallProvider from '@noelthoelen/react-pwa-install';

import ScrollToTop from './ScrollToTop';
import PwaUpdateService from '../../shared/components/PWAUpdateService/PWAUpdateService';

class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: false, // true,
      loaded: true, // false,
    };
  }

  render() {
    const { loaded, loading } = this.state;
    return (
      <CookiesProvider>
        <Provider store={store}>
          <ReactPWAInstallProvider>
            <BrowserRouter>
              <ScrollToTop>
                <>
                  {!loaded
                  && (
                  <div className={`load${loading ? '' : ' loaded'}`}>
                    <div className="load__icon-wrap">
                      <svg className="load__icon">
                        <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                      </svg>
                    </div>
                  </div>
                  )}
                  <div>
                    <Router />
                  </div>
                </>
              </ScrollToTop>
            </BrowserRouter>
          </ReactPWAInstallProvider>
        </Provider>
      </CookiesProvider>
    );
  }
}

export default hot(module)(App);
