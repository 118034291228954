/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import Log from '../../../helpers/Log/Log';

const CardTopBar = (props) => {
  const [searchString, setSearchString] = useState(props.searchString !== null ? props.searchString : '');
  const [showSearchBox, setShowSearchBox] = useState(props.searchString !== null);

  // trap a keypress in the search box
  const handleChange = (e) => {
    setSearchString(e.target.value);
  };
  // Show , hide the searhbox
  const showHideSearchBox = (value) => {
    setShowSearchBox(value);
  };

  // launchSearch -> if enter is pressed or the searchbox magnify is pressed
  // incase, no text is shown, we do hide the searchbox again
  const launchSearch = () => {
    if (searchString !== '') {
      props.search(searchString);
    } else if (props.searchString === null) {
      showHideSearchBox(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      launchSearch();
    }
  };

  const { t } = props;
  Log.trace('render cardTopBar', 'cardTopBar.jsx');
  let pagNext = null;
  let pagPrevious = null;
  if (props.previousCat === null) {
    pagPrevious = (
      <PaginationItem
        className="card-topbar__control-pagination__item"
        disabled={true}
      >
        <PaginationLink
          type="button"
          className="card-topbar__control-pagination__link card-topbar__control-pagination__link--arrow"
        >
          <ChevronLeftIcon className="card-topbar__control-pagination__link-icon" />
        </PaginationLink>
      </PaginationItem>
    );
  } else {
    pagPrevious = (
      <PaginationItem
        className="card-topbar__control-pagination__item"
        tag={Link}
        to={props.previousCat.route}
      >
        <PaginationLink
          type="button"
          className="card-topbar__control-pagination__link card-topbar__control-pagination__link--arrow"
        >
          <ChevronLeftIcon className="card-topbar__control-pagination__link-icon" />
        </PaginationLink>
      </PaginationItem>
    );
  }
  if (props.nextCat === null) {
    pagNext = (
      <PaginationItem
        className="card-topbar__control-pagination__item"
        disabled={true}
      >
        <PaginationLink
          type="button"
          className="card-topbar__control-pagination__link card-topbar__control-pagination__link--arrow"
        >
          <ChevronRightIcon className="card-topbar__control-pagination__link-icon" />
        </PaginationLink>
      </PaginationItem>
    );
  } else {
    pagNext = (
      <PaginationItem
        className="card-topbar__control-pagination__item"
        tag={Link}
        to={props.nextCat.route}
      >
        <PaginationLink
          type="button"
          className="card-topbar__control-pagination__link card-topbar__control-pagination__link--arrow"
        >
          <ChevronRightIcon className="card-topbar__control-pagination__link-icon" />
        </PaginationLink>
      </PaginationItem>
    );
  }
  const searchButton = (
    <PaginationItem
      className="card-topbar__control-pagination__item"
    >
      <PaginationLink
        className="card-topbar__control-pagination__link card-topbar__control-pagination__link--arrow"
        onClick={() => showHideSearchBox(true)}
      >
        <MagnifyIcon className="card-topbar__control-pagination__link-icon" />
      </PaginationLink>
    </PaginationItem>
  );
  let jsx = null;
  if (showSearchBox) {
    jsx = (
      <div className="card-topbar__controls-wrap">
        <div className="card-topbar__controls">
          <h4 className="page-title">
            {props.title}
          </h4>
          <div className="card-topbar__control-search">
            <input
              placeholder={t('topnav.search')}
              value={searchString}
              onChange={handleChange}
              onKeyDown={handleKeyPress}
            />
            <div className="card-topbar__control-search-icon">
              <MagnifyIcon onClick={() => { launchSearch(); }} />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    jsx = (
      <div className="card-topbar__controls-wrap">
        <div className="card-topbar__controls">
          <h4 className="page-title">
            {props.title}
          </h4>
          <div className="card-topbar__control-pagination">
            <Pagination>
              {pagPrevious}
              {pagNext}
              {searchButton}
            </Pagination>
          </div>
        </div>
      </div>
    );
  }

  return jsx;
};

export default (withTranslation(['common'])(CardTopBar));
