/* eslint-disable dot-notation */
/* eslint-disable no-else-return */
export default class Navigation {
  constructor(id) {
    this.Id = String(id);
    this.icon = '';
    this.description = [];
  }

  getDescription(lng, fallBackLng) {
    if (lng in this.description) {
      return this.description[lng];
    } else {
      return this.description[fallBackLng];
    }
  }
}
