import React from 'react'
import { Button } from 'reactstrap'
import { useReactPWAInstall } from '@noelthoelen/react-pwa-install'
import { useTranslation } from 'react-i18next';
import logo from './mtb-logo.png';
import Log from '../../../helpers/Log/Log'

const SidebarInstallBox = () => {
  const { t, i18n } = useTranslation(['common']);
  const { pwaInstall, supported, isInstalled} = useReactPWAInstall();

  const installPWA = () => {
    console.log(i18n.language)
    pwaInstall({
      title: t("install.installTitle"),
      logo: logo,
      description: t("install.installDescription"),
      language: i18n.language
    })
    .then(() => { 
      Log.info('App installed')
     })
  };

console.log("supported", supported(), "installed", isInstalled())
  if (!supported() || isInstalled()) return null;

  return(
    <div className="sidebarInstallBox">
      <div className="sidebarInstallBoxDescription">{t("install.installDesc")}</div>
      <div className="sidebarInstallBoxFooter">
        <Button color="secondary" onClick={installPWA}>{t("install.installButton")}</Button>
      </div>
    </div>
  )
}

export default SidebarInstallBox