/*
  13/08 - bugfix ... error - continuous loop while fetching a specific infocard
  01/09 - Add media carrousel, remove intro image
  01/09 - Refactored to functional  componnt and use of Hooks
  10/09 - Transformed to component where infocardId is passed as props !
  09/10 - BugFix - 266 reloading card gives an error
        - BugFix - 267 mediaimgsrcset not loading correctly
*/
/* eslint-disable eqeqeq */
/* eslint-disable react/no-unused-state */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable arrow-parens */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prefer-stateless-function */
import React, { useState, useEffect } from 'react';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardImg,
  CardText,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import querystring from 'querystring';
import Icon from '@material-ui/core/Icon';
import Carousel from './InfoCardDetailMediaCarousel';
import { infoCardsFetch } from '../../../../redux/actions/index';
import Loading from '../../../../shared/components/loading/loading';
import Alert from '../../../../shared/components/Alert';
import CardDetailTopBar from '../../../../shared/components/cardDetailTopNav/cardDetailTopNav';
import Log from '../../../../helpers/Log/Log';

const LOGMODULE = 'infocardDetailComponent';

const InfoCardDetailComponent = (props) => {
  const [infoCardId, setInfoCardId] = useState(0);
  const dispatch = useDispatch();
  const tenant = useSelector(state => state.gh.tenant);
  const fallBackLng = useSelector(state => state.gh.fallBackLng);
  const destinationId = useSelector(state => state.gh.destinationId);
  const basePath = useSelector(state => state.gh.basePath);
  const infoCards = useSelector(state => state.ifc.infoCards);
  const specificCardloaded = useSelector(state => state.ifc.loadedSpecificCardId);
  const loading = useSelector(state => state.ifc.loading);
  const error = useSelector(state => state.ifc.error);

  const checkFiltersChanged = () => {
    // ... url will contain the card ID
    //     we will check if the given catd ID is already loaded in the state ...
    //     if not, we will load that Id
    const id = props.infoCardId;
    if (id !== infoCardId) {
      setInfoCardId(id);
    }
    Log.trace(`checkFiltersChanged - infoCardID ${id}`, LOGMODULE);
    if (infoCards.findIndex(x => x.infoCardID === id) === -1) {
      // ... in order to prevent endless loop ... we check if we did not already tried to load this infocard
      // if so ... do nothing -> load is already initiated ... so we wait for result
      if (specificCardloaded !== id) {
        Log.trace(`Infocard not found in loaded cards : fetch infocards with id ${id}`, LOGMODULE);
        dispatch(infoCardsFetch(tenant, destinationId, null, null, id));
      }
    }
  };

  // Effect when instructions are given to load another infocard
  // -> url, tenant of destination id changed
  useEffect(() => {
    checkFiltersChanged();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.infoCardId, tenant, destinationId, infoCards]);

  Log.info('render', LOGMODULE);
  const { t } = props;
  const { language } = props.i18n;
  if (destinationId === '') {
    Log.trace('render - no destination loaded, redirect to init', LOGMODULE);
    return <Redirect to="/init" />;
  }
  if (loading) {
    Log.trace('render loading', LOGMODULE);
    return <Loading />;
  }
  if (error !== '') {
    Log.error(`error occurred in loading Infocard: ${error}`, LOGMODULE);
    return (
      <Alert color="danger" icon>
        <p>{t('infocard.loaderror')}</p>
      </Alert>
    );
  }
  let jsx = null;
  const id = props.infoCardId;
  Log.trace(`render infocardDetail - get asked cardId from array ${id}`, LOGMODULE);
  let infoCard = null;
  const i = infoCards.findIndex(x => x.infoCardID === id);
  if (i === -1) {
    Log.trace(`no infoCard loaded ${id}`, LOGMODULE);
    return (
      <Alert color="info" icon>
        <p>{t('infocard.loadcarderror')}</p>
      </Alert>
    );
  }
  infoCard = infoCards[i];
  let previousCard = null;
  let nextCard = null;
  const returnUrl = !props.navBack ? '' : `?returnUrl=${props.navBack}`;
  if (i > 0) {
    previousCard = `${basePath}/InfoCard/${infoCards[i - 1].infoCardID}${returnUrl}`;
  }
  if (i < infoCards.length - 1) {
    nextCard = `${basePath}/InfoCard/${infoCards[i + 1].infoCardID}${returnUrl}`;
  }
  let mediaCarousel = null;
  if (infoCard.media.length > 0) {
    mediaCarousel = <Carousel infoCard={infoCard} />;
  }
  jsx = (
    <>
      <Container className="dashboard">
        <Row>
          <Col xs={12} md={12} lg={8}>
            <Card>
              <CardBody>
                <CardDetailTopBar
                  icon={infoCard.icon}
                  title={infoCard.getTitle(language, fallBackLng)}
                  navPrevious={previousCard}
                  navNext={nextCard}
                  close={props.navBack}
                />
                <CardText
                  className="typography-message"
                  dangerouslySetInnerHTML={{ __html: infoCard.getDetail(language, fallBackLng) ? infoCard.getDetail(language, fallBackLng) : infoCard.getIntroText(language, fallBackLng) }}
                />
                {mediaCarousel}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
  return jsx;
};

export default withTranslation(['guesthouse', 'common'])(InfoCardDetailComponent);
