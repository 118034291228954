/* eslint-disable camelcase */
import common_en from './en/common.json';
import guesthouse_en from './en/guesthouse.json';
import admin_en from './en/admin.json';
import common_nl from './nl/common.json';
import guesthouse_nl from './nl/guesthouse.json';
import admin_nl from './nl/admin.json';
import common_fr from './fr/common.json';
import guesthouse_fr from './fr/guesthouse.json';
import admin_fr from './fr/admin.json';
import common_de from './de/common.json';
import guesthouse_de from './de/guesthouse.json';
import admin_de from './de/admin.json';
import common_es from './es/common.json';
import guesthouse_es from './es/guesthouse.json';
import admin_es from './es/admin.json';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  en: {
    common: common_en,
    guesthouse: guesthouse_en,
    admin: admin_en
  },
  nl: {
    common: common_nl,
    guesthouse: guesthouse_nl,
    admin: admin_nl
  },
  fr: {
    common: common_fr,
    guesthouse: guesthouse_fr,
    admin: admin_fr
  },
  de: {
    common: common_de,
    guesthouse: guesthouse_de,
    admin: admin_de
  },
  es: {
    common: common_es,
    guesthouse: guesthouse_es,
    admin: admin_es
  },
};
