import { saveLogin, resetLogin} from '../../helpers/setting/settings';
import { getUserController } from '../../redux/controller/dataController';

export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGOUT = 'USER_LOGOUT';

// User login is succesfull - update the redux state
export function userLoginSuccess(user) {
  saveLogin(user.loginId)
  return {
    type: USER_LOGIN_SUCCESS,
    user: user,
  };
}

export function userLogoutSuccess() {
  return {
    type: USER_LOGOUT,
  }
}

export const userLogout = (tenant) => {
  return (dispatch) => {
    const datactl = getUserController(tenant);
    datactl.logout()
      .then((res) => {
        resetLogin();
        dispatch(userLogoutSuccess())
      });
  }
}
