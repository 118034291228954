/*
  guestHouse-info state mamagement
  02/01 Added tripcode to get contacts -> in order to load the tourguides
*/
import { getDataController } from '../../redux/controller/dataController';
import Log from '../../helpers/Log/Log';

export const CONTACTS_FETCH_START = 'CONTACTS_FETCH_START';
export const CONTACTS_FETCH_SUCCESS = 'CONTACTS_FETCH_SUCCESS';
export const CONTACTS_FETCH_FAIL = 'CONTACTS_FETCH_FAIL';

function contactsFetchStart() {
  return {
    type: CONTACTS_FETCH_START,
  };
}

function contactsFetchSuccess(contacts) {
  return {
    type: CONTACTS_FETCH_SUCCESS,
    contacts: contacts,
  };
}

function contactsFetchFail(err) {
  return {
    type: CONTACTS_FETCH_FAIL,
    error: err,
  };
}

// async and exported actions
export const contactsFetch = (tenant, guestHouseId, tripcode) => {
  return (dispatch) => {
    dispatch(contactsFetchStart());
    const datactl = getDataController(tenant);

    datactl.getContacts(guestHouseId, tripcode)
      .then((contacts) => {
        Log.trace(contacts, 'contactsFetch');
        dispatch(contactsFetchSuccess(contacts));
      })
      .catch((error) => {
        Log.error(error, 'contactsFetch');
        dispatch(contactsFetchFail(error));
      });
  };
};
