// 09/10 bug267 mediaimgesrcset not loaded correctly
/* eslint-disable no-else-return */
export default class InfoCard {
  constructor(id) {
    this.infoCardID = String(id);
    this.title_ml = [];
    this.category = '';
    this.icon = '';
    this.image = '';
    this.imageType = '';
    this.image_thumbnails = [];
    this.introText_ml = [];
    this.detail_ml = [];
    this.media = []; // array with objects of image, imageType, image_thumbnails
  }

  getTitle(lng, fallback) {
    if (lng in this.title_ml) {
      return this.title_ml[lng];
    }
    return this.title_ml[fallback];
  }

  getIntroText(lng, fallback) {
    if (lng in this.introText_ml) {
      return this.introText_ml[lng];
    }
    return this.introText_ml[fallback];
  }

  getDetail(lng, fallback) {
    if (lng in this.detail_ml) {
      return this.detail_ml[lng];
    }
    return this.detail_ml[fallback];
  }

  getImageSrcSet() {
    if (typeof this.image_thumbnails !== 'undefined') {
      const srcSet = this.image_thumbnails.map((element) => (`${element.url} ${element.width}`));
      return srcSet.toString();
    } else {
      return '';
    }
  }

  static getMediaItemSrcSet(mediaElt) {
    if (typeof mediaElt.image_thumbnails !== 'undefined') {
      const srcSet = mediaElt.image_thumbnails.map((element) => (`${element.url} ${element.width}`));
      return srcSet.toString();
    } else {
      return '';
    }
  }
}
