import React from 'react';
import { Link } from 'react-router-dom';

const TopbarMenuLinks = ({title, icon, path, clicked}) => {
  return (
    <Link className="topbar__link" onClick={clicked} to={path}>
      <span className={`topbar__link-icon lnr lnr-${icon}`} />
      <p className="topbar__link-title">{title}</p>
    </Link>
  );
}

export default TopbarMenuLinks;