import React from 'react';
import { 
  Container,
  Row,
  Col,
} from 'reactstrap';
import CheckIcon from 'mdi-react/CheckThickIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import EyeIconOff from 'mdi-react/EyeOffIcon';
import PropTypes from 'prop-types';

const AdminToolbar = ({
  alertMessage, 
  confirmButton,
  confirmHandler,
  deleteButton,
  deleteHandler,
  publishButton,
  publishHandler,
  unpublishButton,
  unpublishHandler,
  }) => {
  return (
    <Container className="dashboard">
      <Row>
        <Col  xs={12} md={12} lg={8}>
          <div className="adminToolbar_Navbar">
            <div className="adminToolbar_alertContainer">
              {alertMessage ? <p name="alertMessage" className="adminToolbar_alertText" >{alertMessage}</p> : null}
            </div>
            <div className="adminToolbar_buttonContainer">
              {confirmButton ? <button name="confirmButton" className="admin_button admin_button_theme adminToolbar_button" onClick={confirmHandler} data-testid="confirmbutton"><CheckIcon /></button> : null}
              {deleteButton ? <button name="deleteButton" className="admin_button admin_button_theme adminToolbar_button" onClick={deleteHandler} data-testid="deletebutton"><DeleteIcon /></button> : null}
              {publishButton ? <button name="publishButton" className="admin_button admin_button_theme adminToolbar_button" onClick={publishHandler} data-testid="publishButton"><EyeIconOff /></button> : null}
              {unpublishButton ? <button name="unpublishButton" className="admin_button admin_button_theme adminToolbar_button" onClick={unpublishHandler} data-testid="unpubButton"><EyeIcon /></button> : null}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default AdminToolbar;

AdminToolbar.propTypes = {
  alertMessage: PropTypes.string,
  confirmButton: PropTypes.bool,
  confirmHandler: PropTypes.func,
  deleteButton: PropTypes.bool,
  deleteHandler: PropTypes.func,
  publishButton: PropTypes.bool,
  publishHandler: PropTypes.func,
  unpublishButton: PropTypes.bool,
  unpublishHandler: PropTypes.func,
}