import { 
  USER_LOGOUT,
  USER_LOGIN_SUCCESS,
} from '../actions/userActions';

// Under schema usr
const initialState = {
  user: null,           // see model user.js
  loggedIn: false,       // is the user logged in
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        user: action.user,
        loggedIn: true,
      }
    case USER_LOGOUT:
      return {
        ...state,
        user: null,
        loggedIn: false,
      }
    default:
      return state;
  }
}  