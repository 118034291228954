/*
  InitApp
  At this moment, this represents the home page. We refactored the app so we do not need
  the /init?App=xxx&id=yyyy anymore, but each app has its own basepath
  We still keep the /init route for backward compatibility

  Before we show the init page, we do check if the app was loaded before
  In that case, the original basepath is sitored in the cache and we will
  redirect to that base page
*/
import React from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Log from '../../helpers/Log/Log';

const LOGMODULE = 'routerGuestHouse';
const initApp = (props) => {
  const { t } = props;
  // ... check if old init parameters are still passed /init?app=tenant&id=alias
  const urlParams = new URLSearchParams(window.location.search);
  const tenant = urlParams.get('app');
  const alias = urlParams.get('id');
  if (tenant && alias) {
    Log.info('old /init?app=tenant&id=alias used, redirect');
    window.location.replace(`/${tenant}/${alias}`);
  }

  Log.info('render', LOGMODULE);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <h3 className="page-title">{t('init.title')}</h3>
              <p className="typography-message">
                {t('init.yourguide')}
              </p>
              <p className="typography-message">
                {t('init.instructions')}
                <a href="www.mytravelbuddy.com"> www.mytravelbuddy.com</a>
              </p>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default withTranslation(['guesthouse', 'common'])(initApp);
