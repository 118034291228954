/*************
 * Inject the Manifest.JSON into the Index.html instead of linking to a static file
 * This is used to solve the issue with the startUrl
 * On some browser (Safari), the localstorage and cookies are not shared and when installing the App
 * the tenant/alias part of the url is not included
 * 
 * So This function injects a Manifest where the starturl is included
 */

const injectManifestJSON = () => {
  var manifestJSON = 
  {
    "short_name": "MyTravelBuddy",
    "name": "MyTravelBuddy",
    "icons": [
      {
        "src": "https://app.mytravelbuddy.eu/fav.ico",
        "sizes": "64x64 32x32 24x24 16x16",
        "type": "image/x-icon"
      },
      {
        "src": "https://app.mytravelbuddy.eu/logo192.png",
        "type": "image/png",
        "sizes": "192x192",
        "purpose": "any maskable"
      },
      {
        "src": "https://app.mytravelbuddy.eu/logo512.png",
        "type": "image/png",
        "sizes": "512x512"
      }
    ],
    "start_url": ".",
    "display": "standalone",
    "theme_color": "#4ce1b6",
    "background_color": "#ffffff"
  }

  const startUrl = window.location.href;
  manifestJSON.start_url = startUrl;
  const stringManifest = JSON.stringify(manifestJSON);
  const blob = new Blob([stringManifest], {type: 'application/json'});
  const manifestURL = URL.createObjectURL(blob);
  document.querySelector('#my-manifest-placeholder').setAttribute('href', manifestURL);
}

export default injectManifestJSON;