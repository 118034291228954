/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prefer-template */
/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable operator-linebreak */
import React from 'react';
import { CardImg } from 'reactstrap';
import ReactPlayer from 'react-player';

const cardMedia = (props) => {
  let jsx = null;
  if (props.mediaType === 'image') {
    jsx = (
      <CardImg
        bottom
        srcSet={props.imageSrcSet}
        src={props.mediaUrl}
        alt={props.mediaAltText}
        onClick={props.onClick}
      />
    );
  }
  if (props.mediaType === 'video') {
    jsx = (
      <div className="player-wrapper">
        <ReactPlayer
          className="react-player"
          width="100%"
          height="100%"
          volume={0}
          muted={true}
          controls={true}
          loop={true}
          playing={true}
          playsinline={true}
          url={props.mediaUrl}
        />
      </div>
    );
  }
  if (props.mediaType === 'embed') {
    // jsx = props.mediaUrl;
    jsx = (
      <div className="player-wrapper">
        <ReactPlayer
          className="react-player"
          width="100%"
          height="100%"
          volume={0}
          muted={true}
          controls={true}
          loop={true}
          playing={true}
          playsinline={true}
          url={props.mediaUrl.url}
        />
      </div>
    );
  }

  return jsx;
};

export default cardMedia;
