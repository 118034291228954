/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';
import Icon from '@material-ui/core/Icon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon';
import Log from '../../../helpers/Log/Log';

const cardDetailTopBar = (props) => {
  Log.trace('render cardDetailTopBar', 'cardDetailTopBar.jsx');
  let pagNext = null;
  let pagPrevious = null;
  let closeButton = null;
  // ... do not show navigation in case we have only 1 card
  if (props.navPrevious || props.navNext) {
    if (!props.navPrevious) {
      pagPrevious = (
        <PaginationItem
          className="card-topbar__control-pagination__item"
          disabled={true}
        >
          <PaginationLink
            type="button"
            className="card-topbar__control-pagination__link card-topbar__control-pagination__link--arrow"
          >
            <ChevronLeftIcon className="card-topbar__control-pagination__link-icon" />
          </PaginationLink>
        </PaginationItem>
      );
    } else {
      pagPrevious = (
        <PaginationItem
          className="card-topbar__control-pagination__item"
          tag={Link}
          to={props.navPrevious}

        >
          <PaginationLink
            type="button"
            className="card-topbar__control-pagination__link card-topbar__control-pagination__link--arrow"
          >
            <ChevronLeftIcon className="card-topbar__control-pagination__link-icon" />
          </PaginationLink>
        </PaginationItem>
      );
    }
    if (!props.navNext) {
      pagNext = (
        <PaginationItem
          className="card-topbar__control-pagination__item"
          disabled={true}
        >
          <PaginationLink
            type="button"
            className="card-topbar__control-pagination__link card-topbar__control-pagination__link--arrow"
          >
            <ChevronRightIcon className="card-topbar__control-pagination__link-icon" />
          </PaginationLink>
        </PaginationItem>
      );
    } else {
      pagNext = (
        <PaginationItem
          className="card-topbar__control-pagination__item"
          tag={Link}
          to={props.navNext}
        >
          <PaginationLink
            type="button"
            className="card-topbar__control-pagination__link card-topbar__control-pagination__link--arrow"
          >
            <ChevronRightIcon className="card-topbar__control-pagination__link-icon" />
          </PaginationLink>
        </PaginationItem>
      );
    }
  }
  if (props.close) {
    closeButton = (
      <PaginationItem
        className="card-topbar__control-pagination__item"
        tag={Link}
        to={props.close}
      >
        <PaginationLink
          type="button"
          className="card-topbar__control-pagination__link card-topbar__control-pagination__link--arrow"
        >
          <ArrowLeftIcon className="card-topbar__control-pagination__link-icon" />
        </PaginationLink>
      </PaginationItem>
    );
  }

  return (
    <div className="card-topbar__controls-wrap">
      <div className="card-topbar__controls">
        <h4 className="page-title">
          <Icon className="card-topbar__icon" onClick={props.clicked}>{props.icon}</Icon>
          &nbsp;
          {props.title}
        </h4>
        <div className="card-topbar__control-pagination">
          <Pagination>
            {pagPrevious}
            {pagNext}
            {closeButton}
          </Pagination>
        </div>
      </div>
    </div>
  );
};

export default cardDetailTopBar;
