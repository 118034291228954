/*
  TopbarLanguage
  Component showing the current language and dropdown picker to change language

  22/10 Transformed to functional components using Hooks
        monitor SupportedLanguages state, and reinit dropdown in case of change
  18/08 Changed to functional component with hooks because the default set language in the
        backend is not shown
*/
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Collapse } from 'reactstrap';
import DownIcon from 'mdi-react/ChevronDownIcon';
import Log from '../../../helpers/Log/Log';
import { sendEvent } from '../../../helpers/analytics/analytics';

const en = `${process.env.PUBLIC_URL}/img/language/en.png`;
const nl = `${process.env.PUBLIC_URL}/img/language/nl.png`;
const fr = `${process.env.PUBLIC_URL}/img/language/fr.png`;
const es = `${process.env.PUBLIC_URL}/img/language/es.png`;
const de = `${process.env.PUBLIC_URL}/img/language/ge.png`;

const EnLng = () => (
  <span className="topbar__language-btn-title">
    <img src={en} alt="en" />
    <span>EN</span>
  </span>
);

const FrLng = () => (
  <span className="topbar__language-btn-title">
    <img src={fr} alt="fr" />
    <span>FR</span>
  </span>
);

const NlLng = () => (
  <span className="topbar__language-btn-title">
    <img src={nl} alt="nl" />
    <span>NL</span>
  </span>
);

const EsLng = () => (
  <span className="topbar__language-btn-title">
    <img src={es} alt="es" />
    <span>ES</span>
  </span>
);

const DeLng = () => (
  <span className="topbar__language-btn-title">
    <img src={de} alt="de" />
    <span>GE</span>
  </span>
);

const LOGMODULE = 'topbarLanguage';

const TopbarLanguage = (props) => {
  const [collapse, setCollapse] = useState(false);
  const [mainButtonContent, setMainButtonContent] = useState(<EnLng />);
  const supportedLngs = useSelector((state) => state.gh.supportedLngs);
  const fallBackLng = useSelector((state) => state.gh.fallBackLng);

  const toggle = () => {
    Log.info('toggle menu', LOGMODULE);
    setCollapse(!collapse);
  };

  const initDropDown = (lng) => {
    switch (lng) {
      case 'en':
        return <EnLng />;
      case 'fr':
        return <FrLng />;
      case 'nl':
        return <NlLng />;
      case 'es':
        return <EsLng />;
      case 'de':
        return <DeLng />;
      case 'cimode':
        return null;
      default:
        return <EnLng />;
    }
  };

  const changeLanguage = (lng) => {
    Log.info(`changeLanguage - ${lng}`, LOGMODULE);
    const { i18n } = props;
    i18n.changeLanguage(lng);
    sendEvent({category: 'Initialize', action: 'Change language', label: lng});
    toggle();
  };

  useEffect(() => {
    Log.info('initDropDown', LOGMODULE);
    if (supportedLngs) {
      Log.info('reinit i18n with supported languages', LOGMODULE);
      props.i18n.init({
        supportedLngs: [...supportedLngs],
        fallbackLng: fallBackLng,
      });
    }
    setMainButtonContent(initDropDown(props.i18n.language));
  // eslint-disable-next-line react/destructuring-assignment
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.i18n.language, supportedLngs]);

  const { i18n } = props;
  Log.info('render', LOGMODULE);

  return (
    <div className="topbar__collapse topbar__collapse--language">
      <button className="topbar__btn" type="button" onClick={toggle}>
        {mainButtonContent}
        <DownIcon className="topbar__icon" />
      </button>
      {collapse && <button className="topbar__back" type="button" onClick={toggle} />}
      <Collapse
        isOpen={collapse}
        className="topbar__collapse-content topbar__collapse-content--language"
      >
        {i18n.options.supportedLngs.map((lng) => {
          if (lng !== 'cimode') {
            return (
              <button
                key={lng}
                className="topbar__language-btn"
                type="button"
                onClick={() => changeLanguage(lng)}
              >
                {initDropDown(lng)}
              </button>
            );
          }
          return null;
        })}
      </Collapse>
    </div>
  );
};

export default withTranslation('common')(TopbarLanguage);
