import { useEffect, useState } from 'react';
import * as serviceWorkerRegistration from '../../../serviceWorkerRegistration';
import { useTranslation } from 'react-i18next';
import { Toast, Button, ToastHeader, ToastBody } from 'reactstrap'

const PWAUpdateService = () => {
  const { t } = useTranslation(['common']);
  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState(null)

// ... new version of the app
  const onSWUpdate = (registration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  }

  useEffect(() => {
    serviceWorkerRegistration.register({ onUpdate: onSWUpdate })
  }, [])

// ... click for the relaod now button in the snackbar
  const reloadPage = () => {
    waitingWorker?.postMessage({type: 'SKIP_WAITING'});
    setShowReload(false);
    window.location.reload();
  }

  if (!showReload) return null
  return(
    <div className="snackBarContainer">
      <Toast 
        isOpen="true"
        transition={{in: true}}
      >
        <ToastHeader toggle={() => setShowReload(false)}>
          MyTravelBuddy
        </ToastHeader>
        <ToastBody>
          <div className="snackBarBody">
            <span>{t("newVersion.newVersionMessage")} </span>
            <div>
              <Button
                color="primary"
                size="small"
                onClick={reloadPage}
              >
                {t("newVersion.reloadButton")}
              </Button>
            </div>
          </div>
        </ToastBody>
      </Toast>
    </div>
    )


}

export default PWAUpdateService;