/* eslint-disable object-shorthand */
/* eslint-disable no-undef */
/* eslint-disable arrow-body-style */
/*
  guestHouse-info state mamagement
*/
import { getDataController } from '../../redux/controller/dataController';
import Log from '../../helpers/Log/Log';

export const DESTINATION_FETCH_START = 'DESTINATION_FETCH_START_FETCH_START';
export const DESTINATION_FETCH_SUCCESS = 'DESTINATION_FETCH_SUCCESS';
export const DESTINATION_FETCH_FAIL = 'DESTINATION_FETCH_FAIL';

function destinationFetchStart() {
  return {
    type: DESTINATION_FETCH_START,
  };
}

function destinationFetchSuccess(dest) {
  return {
    type: DESTINATION_FETCH_SUCCESS,
    destination: dest,
  };
}

function destinationFetchFail(err) {
  return {
    type: DESTINATION_FETCH_FAIL,
    error: err,
  };
}

// async and exported actions
export const destinationFetch = (tenant, guestHouseId) => {
  return (dispatch) => {
    Log.trace('Start loading destination ...', 'destinationFetch');
    dispatch(destinationFetchStart());
    const datactl = getDataController(tenant);

    datactl.getDestination(guestHouseId)
      .then((dest) => {
        Log.trace(dest, 'destinationFetch');
        dispatch(destinationFetchSuccess(dest));
      })
      .catch((error) => {
        Log.error(error, 'destinationFetch');
        dispatch(destinationFetchFail(error));
      });
  };
};
