/* GetLangObject
  I translationArr  Array containing all fetched translations
    I fieldname       fieldname to extract
*/
export function getLangObject(trArr, fieldName) {
  const mlArr = [];
  trArr.forEach(element => {
    mlArr[element.language] = element[fieldName];
  });
  return mlArr;
}

/* GetSizedImages
  I assetsUrl Url to fetch sized assets from directus (ex http://localhost:8080/directus/assets/)
    make sure to end with /
  I sizesArr  Array containing sizes.
    ex {key: "1-small", sizeparam: "?w=680&h=680&f=crop&q=100", size: "2x"}
  I imgFld  image field containing original image
  I crop    boolean -> true if images are cropped ; false - no crop
  Returns Array with multiple values
    Array with key, url, width -> can be used to set srcSet for images
    default image url which will be the largest cropped image
    image type
*/
// eslint-disable-next-line no-unused-vars
export function getSizedImages(assetsUrl, imgArr, imgFld, crop) {
  const imageType = imgFld.type.toString();
  // ... check if an image is set
  if (imageType.substr(0, 5) === 'image' || imageType === 'application/octet-stream' || imageType === 'application/ico') {
    const tmbArr = [];
    if (imgArr) {
      const url = `${assetsUrl}${imgFld.private_hash}`;
      imgArr.forEach((elt) => {
        tmbArr.push({ key: elt.key, url: `${url}?${elt.sizeParam}`, width: elt.width });
      });
    }
    const tmbSortedArr = tmbArr.sort(
      function (a, b) {
        const keyA = a.key.toUpperCase(); // ignore upper and lowercase
        const keyB = b.key.toUpperCase(); // ignore upper and lowercase
        if (keyA < keyB) {
          return -1;
        }
        if (keyA > keyB) {
          return 1;
        }
        // names must be equal
        return 0;
      },
    );
    if (crop) {
      return [tmbSortedArr, tmbSortedArr[tmbSortedArr.length - 1].url, 'image'];
    } else {
      return [tmbSortedArr, imgFld.data.full_url, 'image'];
    }
  }
  // ... check for a video file
  if (imageType.substr(0, 5) === 'video') {
    return [[], imgFld.data.full_url, 'video'];
  }
  // ... check for a embedded video
  if (imageType.substr(0, 5) === 'embed') {
    return [[], imgFld.data.embed, 'embed'];
  }
  return ([], '', '');
}

