/* eslint-disable react/prop-types */
import React from 'react';
import Carousel from '../../../../shared/components/carousel/CarouselSingle';
import CardMedia from '../../../../shared/components/cardMedia/CardMedia';
import InfoCard from '../../../../redux/models/InfoCard';

const infoCardDetailMediaCarousel = (props) => {
  // eslint-disable-next-line
  const images = props.infoCard.media.map((crElt) => (
    <div key={crElt.image}>
      <CardMedia
        width="100%"
        mediaType={crElt.imageType}
        imageSrcSet={InfoCard.getMediaItemSrcSet(crElt)}
        mediaUrl={crElt.image}
      />
    </div>
  ));
  return (
    <Carousel>
      {images}
    </Carousel>
  );
};

export default infoCardDetailMediaCarousel;
