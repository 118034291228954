/* eslint-disable arrow-body-style */
/* eslint-disable object-shorthand */
import { getDataController } from '../../redux/controller/dataController';
import Log from '../../helpers/Log/Log';

export const INFOCARDS_FETCH_START = 'INFOCARDS_FETCH_START';
export const INFOCARDS_FETCH_SUCCESS = 'INFOCARDS_FETCH_SUCCESS';
export const INFOCARDS_FETCH_FAIL = 'INFOCARDS_FETCH_FAIL';

function infoCardsFetchStart(category, filter, id) {
  return {
    type: INFOCARDS_FETCH_START,
    category: category,
    searchFilter: filter,
    specificCardId: id,
  };
}

function infoCardsFetchSuccess(infoCards) {
  return {
    type: INFOCARDS_FETCH_SUCCESS,
    infoCards: infoCards,
  };
}

function infoCardsFetchFail(err) {
  return {
    type: INFOCARDS_FETCH_FAIL,
    error: err,
  };
}

// async and exported actions
export const infoCardsFetch = (tenant, guestHouseId, category, filter, id) => {
  return (dispatch) => {
    Log.trace(`set loading category = ${category}, search=${filter}`, 'infoCardfetch');
    dispatch(infoCardsFetchStart(category, filter, id));
    const datactl = getDataController(tenant);

    datactl.getInfoCards(guestHouseId, category, filter, id)
      .then((infoCards) => {
        Log.trace(infoCards, 'infoCardsFetch');
        dispatch(infoCardsFetchSuccess(infoCards));
      })
      .catch((error) => {
        Log.error(error, 'infoCardsFetch');
        dispatch(infoCardsFetchFail(error));
      });
  };
};
