import React, { Fragment } from 'react';
import { Card, CardBody, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { cookieHtml } from './cookiePolicy_en';
import { COOKIE_CONSENTDATE } from '../../shared/components/cookieConsent/cookieConsent';
import packageJson from '../../../package.json';

const About = () => {
  const { t } = useTranslation('common');
  const [cookies, , removeCookie] = useCookies([COOKIE_CONSENTDATE]);
  const consentDate = new Date(cookies[COOKIE_CONSENTDATE]);
  return (
    <Fragment>
      <Card>
        <CardBody>
          <p className="typography-message">
            {t('about.version', { version: packageJson.version })}
          </p>
          <p className="typography-message">{t('cookie.consented')} {consentDate.toLocaleDateString()}</p>
          <div>
            <Button
              color="link"
              size="sm"
              onClick={() => removeCookie(COOKIE_CONSENTDATE, { path: '/' })}
            >
              {t('cookie.changebutton')}
            </Button>
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <p
            className="typography-message"
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{ __html: cookieHtml }}
          />
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default About;
