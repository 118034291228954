import React from 'react';
import { render } from 'react-dom';
import App from './containers/App/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import injectManifestJSON from './containers/PWAServices/injectManifestJSON'
import './translations/i18n';

injectManifestJSON();
render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// Noël 10/10/21 -> Moved to PWAUpdateService
////serviceWorkerRegistration.register();