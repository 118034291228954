export default class Trip {
  constructor(trip) {
    if (!trip) {
      this.tripCode = '';             // Unique code for the trip
      this.tourguides = [];           // array of tourguides for the trip
      this.start = null;              // start date of the trip
      this.end = null;                // end date of the trip
      this.publicProgram = [];        // public visible program -> Array of dates where somethins is programmed (by one of the tourguides)
    } else {
      this.tripCode = trip.tripCode;
      this.tourguides = trip.tourguides;
      this.start = trip.start;
      this.end = trip.end;
      this.publicProgram = trip.publicProgram;
    }
  }

  getProgramNav() {
    const end = this.end;
    const start = this.start;
    const nbrDays = (end - start) / (24*60*60*1000);
    const programNav = [];
    for (let i = 0; i <= nbrDays; i++ ) {
      let pgDate = new Date(start);
      pgDate.setDate(start.getDate() + i);
      const key = pgDate.toISOString().substring(0,10);
      programNav.push(key);
    }
    return programNav;
  }

  // get the day number (first, 2d) for a given date
  // returns -1 in case not part of trip
  getDayNbr(date) {
    const dt = new Date(date);
    if (this.start <= dt && dt <= this.end) {
      return dt.getDate() - this.start.getDate() + 1;
    } else {
      return -1
    }
  }
}