import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  Container,
  Button,
  Form,
  FormGroup,
  Row,
  Col,
  FormFeedback,
  Label,
  Input,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Alert from '../../shared/components/Alert';
import Loading from '../../shared/components/loading/loading';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { userLogout, userLoginSuccess } from '../../redux/actions/index';
import log from '../../helpers/Log/Log';
import { regExEmailValidator } from '../../helpers/validators/validators';
import { getUserController } from '../../redux/controller/dataController';
import { saveLoginEmail, getLoginEmail} from '../../helpers/setting/settings';
import { InvalidUserError, InvalidUserCredentials, UserInactiveError, UserSuspendedError } from '../../redux/controller/dataController';
import Log from '../../helpers/Log/Log';

const LOGMODULE = "LoginForm";

const LoginForm = () => {
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      email: getLoginEmail(),
    }
  });
  const { t } = useTranslation('common');
  const tenant = useSelector(state => state.gh.tenant);
  const loggedInUser = useSelector(state => state.usr.user);
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [busyChecking, setBusyChecking] = useState(false);

  // ... Logout
  const onLogoutClick = () => {
    log.trace('onLogoutClick', LOGMODULE);
    dispatch(userLogout());
  }
  const onLoginClick = async (data) => {
    setError('');
    setBusyChecking(true);
    log.trace('onloginClick', LOGMODULE);
    const datactl = getUserController(tenant);
    try {
      const user = await datactl.login(data.email, data.password);     
      dispatch(userLoginSuccess(user));
      saveLoginEmail(data.remember_me ? data.email : null);

    } catch(ex) {
      switch (true) {
        case ex instanceof InvalidUserError:
          Log.error(`InvalidUserError: ${ex.message}`, LOGMODULE);
          setError(t('login.invaliduser'));
          break;
        case ex instanceof InvalidUserCredentials:
          Log.info(`InvalidUserCredentials: ${ex.message}`, LOGMODULE);
          setError(t('login.invalidcredentials'));
          break;
        case ex instanceof UserInactiveError:
          Log.info('User inactive', LOGMODULE);
          setError(t('login.userinactive'));
          break;  
        case ex instanceof UserSuspendedError:
          Log.info('User suspended', LOGMODULE);
          setError(t('login.usersuspended'));
          break;    
        default:
          Log.error(`Error during login: ${ex.message}`, LOGMODULE);
          setError(t('login.error'));
      }
    }
    setBusyChecking(false);
  }

  // Show error message if error
  let alertMsg = ''
  if (error) {
    alertMsg = (
      <Alert color="warning" icon={true}><p>{error}</p></Alert>
    )
  }

  // ... in case already logged in
  if (loggedInUser) {
    return (
      <Container className="dashboard">
        {alertMsg}
        <Form className="form__max400">
          <FormGroup className="form__form-group">  
            <Label>{t('login.loggedinas', {username: loggedInUser.userName})}</Label>
          </FormGroup>
          <Button className="logoutbutton" onClick={onLogoutClick} data-testid="logout">{t('login.logout')}</Button>
        </Form>
      </Container>
    );
  }

  return (
    <Container className="dashboard">
      {alertMsg}
      {busyChecking ? <Loading /> : null}
      <Form className="form__max400" onSubmit={handleSubmit(onLoginClick)}>
        <Row form>
          <Col xs="1">
            <AccountOutlineIcon className="form__form-group-icon"/>
          </Col>
          <Col xs="10">
            <FormGroup inline>
              <Label for="email">{t('login.email')}</Label>
              <Input
                invalid={errors.email ? true : false} 
                type="text" 
                name="email" 
                id="email" 
                placeholder={t('login.emailplaceholder')} 
                innerRef={
                  register({
                    required: t('login.emailrequired'), 
                    pattern: {
                      value: regExEmailValidator, 
                      message: t('login.emailinvalid')
                    }
                  })
                }
              />
              <FormFeedback data-testid="emailerror">{errors.email?.message}</FormFeedback>
            </FormGroup>
          </Col>
          <Col>
          </Col>
        </Row>
        <Row form>
          <Col xs="1">
            <KeyVariantIcon className="form__form-group-icon"/>
          </Col>
          <Col xs="10">
            <FormGroup inline>
              <Label for="password">{t('login.password')}</Label>
              <Input
                invalid={errors.password ? true : false} 
                type={showPassword ? "text" : "password"} 
                name="password" 
                id="password" 
                placeholder={t('login.passworplaceholder')} 
                data-testid="inputpw"
                innerRef={
                  register({
                    required: t('login.passwordrequired')
                  })
                }
              />
              <FormFeedback data-testid="pwerror">{errors.password?.message}</FormFeedback>
            </FormGroup>
            {/*<div className="forgotPassword">
              <Link to="/">{t('login.forgetpassword')}</Link>
            </div>*/}
          </Col>
          <Col xs="1">
            {!showPassword ? 
              <VisibilityOutlinedIcon className="form__form-group-icon" data-testid="pwon" onClick={() => setShowPassword(true)} /> 
            : 
              <VisibilityOffOutlinedIcon className="form__form-group-icon" data-testid="pwoff" onClick={() => setShowPassword(false)}/>
            }
          </Col>
        </Row>
        <Row>
          <Col xs="1">
          </Col>
          <Col>
            <FormGroup>
              <Label>
                <Input type="checkbox"
                  name="remember_me"
                  innerRef={register}
                  id="remember_me"
                />
                {t('login.rememberme')}
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <FormGroup >
          <Button type="submit" className="loginbutton" disabled={busyChecking}>{t('login.signin')}</Button>
        </FormGroup>
      </Form>
    </Container>
  );
};
export default LoginForm;
