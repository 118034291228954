import {
  CONTACTS_FETCH_START,
  CONTACTS_FETCH_SUCCESS,
  CONTACTS_FETCH_FAIL,
} from '../actions/contactsActions';

const initialState = {
  contacts: [],
  loading: false,
  error: '',
};

export default function contactsReducer(state = initialState, action) {
  switch (action.type) {
    case CONTACTS_FETCH_START:
      return {
        ...state,
        loading: true,
        contacts: [],
        error: '',
      };
    case CONTACTS_FETCH_SUCCESS:
      return {
        ...state,
        contacts: [...action.contacts],
        loading: false,
        error: '',
      };
    case CONTACTS_FETCH_FAIL:
      return {
        ...state,
        loading: false,
        contacts: [],
        error: action.error,
      };
    default:
      return state;
  }
}
