/* eslint-disable class-methods-use-this */
/* Logging class
  in order to turn on console logging:
  add the following key to the localstorage environment
    debug   -> value : mytravelbuddy.*    -> turns on all logging for mytravelbuddy
    debug   -> value : mytravelbuddy.router.* -> turns on all logging for the router component
    debug   -> value : mytravelbuddy.*.info -> only info messages
*/
import debug from 'debug';
import { sendException } from '../analytics/analytics';

const COLOURS = {
  trace: 'lightblue',
  info: 'blue',
  warn: 'pink',
  error: 'red',
}; // choose better colours :)

class Log {
  /* constructor() {
    if (process.env.REACT_APP_BUILD !== 'PRODUCTION') {
      localStorage.setItem('debug', 'mytravelbuddy:*');
    }
  }*/


  generateMessage(level, message, source) {
    // Set the prefix which will cause debug to enable the message
    const namespace = `mytravelbuddy:${source}:${level}`;
    const createDebug = debug(namespace);

    // Set the colour of the message based on the level
    createDebug.color = COLOURS[level];

    createDebug(message);
  }

  trace(message, source) {
    return this.generateMessage('trace', message, source);
  }

  info(message, source) {
    return this.generateMessage('info', message, source);
  }

  warn(message, source) {
    return this.generateMessage('warn', message, source);
  }

  error(message, source) {
    sendException(message);
    return this.generateMessage('error', message, source);
  }
}

export default new Log();
