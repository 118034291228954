/*
  usercontroller

  04/02   user controller 
*/
import DirectusIO from './directusIO';
import {
  InvalidUserError,
  InvalidUserCredentials,
  UserInactiveError,
  UserSuspendedError,
  SessionExpiredError,
} from '../dataController';
// import { getLangObject, getSizedImages } from './directusHelper';
import Log from '../../../helpers/Log/Log';
import User from '../../models/User';

const LOGMODULE = 'usercontroller';

export default class UserController {
  constructor(tenant) {
    this.dirIO = new DirectusIO(tenant);
  }

  /*
    Login
    returns   user: User,    -> dee user model
    is case of error throws
              generalFetchError
  */
  async login(email, password) {
    Log.trace(`login:${email}`, LOGMODULE);

    try {
      const json = await this.dirIO.login(email,password);
      Log.info(json, LOGMODULE);
      // in case of admin -> full rights
      if (json.data.user.role === '1') {
        const user = new User();
        user.loginId = json.data.user.id;
        user.userName = `${json.data.user.first_name} ${json.data.user.last_name}`.trim();
        user.email = json.data.user.email;
        user.isAdminRole = true;
        return user;
      } else {
        // other user - refresh the user ... who is logging in on the tenant. Should be related!
        const user = await this.refreshUser(json.data.user.id);
        return user;
      };
    } catch(ex) {
      if (ex.message === 'Invalid user credentials') {
        throw(new InvalidUserCredentials(`Invalid user credentials for email`));
      } else if (ex.message === 'User inactive') {
        throw(new UserInactiveError(`User inactive`));
      } else if (ex.message.substr(0,25) === 'Your account is suspended') {
        throw(new UserSuspendedError('User suspended'));
      }
      throw(ex)
    }
  }

  /*
    RefreshUser - userId and token will be stored in localstorage
              This function can be used to refresh the user (check if token is still valid)
              when starting the app
    I         loginId   Id of the user (is not the contact!)
    returns   user: User,
    is case of error throws
              generalFetchError
  */
  async refreshUser(loginId) {
    Log.trace(`refreshUser:${loginId}`, LOGMODULE);
    if (!loginId) {
      Log.info('not logged in - continue', LOGMODULE);
      return null;
    }
    try {
      const param = {
        'filter[login][eq]': loginId,
        'fields': 'id,name,photo.*,email, login.email, login.role.name'
      };
      const cont = await this.dirIO.getProtectedItems('contact', param);
      Log.info('refetch logged in user - token is still valid', LOGMODULE);
      Log.info(cont, LOGMODULE);
      const user = new User();
      if (cont.data.length === 0) {
        // user niet mee geldig
        Log.error(`user with id ${loginId} is not linked to a contact.`, LOGMODULE);
        throw(new InvalidUserError(`user with id ${loginId} is not linked to a contact.`));
      }
      //TODO : check of login nog active is !
      // niet nodig denk ik omwille dat we sowieso proberen in te loggen
      const dt = cont.data[0];
      user.loginId = loginId;
      user.contactId = dt.id;
      user.userName = dt.name;
      user.email = dt.email;
      if (dt.photo) {
        if (dt.photo.type.substr(0, 5) === 'image' || dt.photo.type === 'application/octet-stream' || dt.photo.type === 'application/ico') {
          const url = `${this.dirIO.config.url}/${this.dirIO.config.project}/assets/`;
          user.photoUrl = `${url}${dt.photo.private_hash}?w=32&h=32&f=contain&q=100`;
        }
      }
      // !! Directus is configured, you can only get yor own role
      switch (dt.login.role?.name?.toLowerCase()) {
        case 'owner':
          user.isOwnerRole = true;
          break;
        case 'organisation':
          user.isOrganisationRole = true;
          break;
        case 'tourguide':
          user.isTourGuideRole = true;
          break;
        default:
      }
      return user
    } catch (ex) {
      if (ex.message === 'Unauthorized request') {
        Log.warn('Refresh user - session expired', LOGMODULE);
        throw(new SessionExpiredError('Unauthorized'));
      }
      throw(ex);
    }    
  }

  async logout() {
    this.dirIO.logout();
  }
}