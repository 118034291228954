/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
} from 'reactstrap';
// import DataUsage from '@material-ui/icons/DataUsageRounded';
import Icon from '@material-ui/core/Icon';
import CardMedia from '../../../../shared/components/cardMedia/CardMedia';
import Log from '../../../../helpers/Log/Log';

const LOGMODULE = 'infocardlistitem';

const infoCardListItem = (props) => {
  Log.trace('render infoCardListItem', LOGMODULE);
  return (
    <Card>
      <CardBody className="infoCardList__cardBody" onClick={props.clicked}>
        <CardTitle onClick={props.clicked}>
          <h4 className="page-title"><Icon className="infoCardList__icon" onClick={props.clicked}>{props.icon}</Icon> {props.title}</h4>
        </CardTitle>
        <CardText onClick={props.clicked}>{props.introText}</CardText>
      </CardBody>
      {props.image !== ''
        ? (
          <div className="infoCardList__image">
            <CardMedia
              width="100%"
              mediaType={props.imageType}
              imageSrcSet={props.imageSrcSet}
              mediaUrl={props.image}
              onClick={props.clicked}
            />
          </div>
        ) : null }
    </Card>
  );
};

export default infoCardListItem;
